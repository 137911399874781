import Line from "common/Line";
import { CourseExamPageContext } from "contextAPI/CourseExamPageContext";
import React, { useContext } from "react";
// css
import "./index.scss";

const QuestionAnalysis = () => {
  const { answers, total_questions } = useContext(CourseExamPageContext);

  let questionsList = [];

  for (let index = 1; index <= total_questions; index++) {
    questionsList.push(index);
  }

  return (
    <div className="questionAnalysis">
      <h4>Question Analysis</h4>
      <Line orientation="horizontal" />
      <div className="questionAnalysis__list">
        {questionsList.map((question, index) => {
          const filteredValue = answers.find(
            (obj) => parseInt(obj.question_no) === parseInt(question)
          );

          return (
            <div
              key={index}
              className={`${
                parseInt(filteredValue?.question_no) === parseInt(question) &&
                "active"
              }`}
            >
              {question}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(QuestionAnalysis);
