import React from 'react';
import './index.scss';

const Line = ({ orientation }) => {
  return (
    <>
      {orientation === 'horizontal' && <hr className='horizontal-line' />}
      {orientation === 'vertical' && <hr className='vertical-line' />}
    </>
  );
};

export default Line;
