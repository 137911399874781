import styled from "styled-components";

export const Wrapper = styled.div`
  margin: auto;
  max-width: 1280px;
  margin-bottom: 100px;
  .policy__title {
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 23px;
    line-height: 24px;
    color: #111111;
  }
  div {
    list-style-position: inside;

    &::marker {
      font-size: 16px;
      line-height: 24px;
      color: #111111;
    }
  }

  .policy__list {
    font-size: 16px;
    line-height: 24px;
    color: #7a7a7a;
    dd {
      margin-left: 0px;
      margin-top: -10px;
      margin-bottom: 10px;
      width: 800px;
      font-size: 18px;
      span > a {
        font-weight: 550;
        color: #4bbd97;
      }
      ul {
        margin: 1rem 1.5rem;
        li {
          font-size: 18px;
          color: blue;
          line-height: 2rem;
        }
        li:hover {
          color: black;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    padding: 0 16px;
    width: 100%;
  }
  @media screen and (max-width: 930px) {
    padding: 0 16px;
    .policy__list {
      dd {
        width: 90%;
      }
    }
  }
`;
