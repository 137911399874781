import CancelButton from "common/CancelButton";
import HeaderContainer from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/HeaderContainer";
import React from "react";
import LeaderboardTable from "../LeaderboardTable/index";
// css
import "./index.scss";

function LeaderboardModal({ dat, setOpenModal, handleClose }) {
  return (
    <div className="leaderboardModal">
      <div className="leaderboardModal__header">
        <HeaderContainer
          title="Leaderbord"
          subtitle="You can always switch to other exam categories anytime."
          className="leaderboardModal__header--titleContainer"
        />

        <CancelButton onClick={handleClose} />
      </div>
      <div className="leaderboardModal__body">
        <LeaderboardTable dat={dat} leaderboardmodal="true" />
      </div>
    </div>
  );
}

export default LeaderboardModal;
