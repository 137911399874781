import classNames from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'common/CircularProgress';
// css
import './index.scss';

const Button = ({
  children,
  style,
  onClick,
  color,
  variant,
  disabled,
  size,
  isLoading,
  className = '',
  ...rest
}) => {
  return (
    <button
      style={style}
      className={classNames(
        className,
        `btn ${isLoading | disabled && 'disabled'}`,
        {
          'btn__main--contained': color === 'main' && variant === 'contained',
          'btn__sub--outlined': color === 'sub' && variant === 'outlined',
          'btn__main--outlined': color === 'main' && variant === 'outlined',
          'btn__gradient--contained':
            color === 'gradient' && variant === 'contained',
          [`btn--${size}`]: size,
        }
      )}
      onClick={onClick}
      disabled={isLoading | disabled}
      {...rest}
    >
      {isLoading ? (
        <CircularProgress size='sm' color='#fff' />
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['main', 'sub', 'gradient']),
  variant: PropTypes.oneOf(['contained', 'outlined']),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']).isRequired,
};

export default memo(Button);
