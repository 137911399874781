import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// import logger from 'redux-logger';
// slice
import courseExamReducer from "pages/CourseExamPage/courseExamSlice";
import loginReducer from "pages/AuthenticationPage/Login/LoginSlice";
import appReducer from "pages/AuthenticationPage/appSlice";
import registerReducer from "pages/AuthenticationPage/Register/userSlice";
import categoryListReducer from "components/Header/Category/CategoryModal/slice";
import userPreferenceReducer from "./features/userPreferenceSlice";
import notificationReducer from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/RightContent/Notification/NotificationsSlice.js";
// import filterContentReducer from './features/filterContentSlice';
// api
import { advertisementApi } from "./services/advertisementApi";
import { universityInfoApi } from "./services/universityInfoApi";
import { courseListApi } from "./services/courseListApi";
import { userPreferenceApi } from "./services/userPreferenceApi";
import { questionSetApi } from "./services/questionSetApi";
import { entranceQuestionApi } from "./services/entranceQuestionApi";
import { leaderboardListApi } from "./services/leaderboardApi";
import { userProfileApi } from "./services/userProfileApi";
import { notificationsApi } from "./services/NotificationsApi";
import { recentQuizApi } from "./services/recentQuizApi";
import { myPerformanceApi } from "./services/myPerformanceApi";
import { discussionForumApi } from "./services/discussionForumApi";
import { discussionCommentApi } from "./services/discussionCommentApi";
import { courseSubjectApi } from "./services/courseSubjectApi";
import { quizDetailsApi } from "./services/quizDetailsApi";
// const middleware = (getDefaultMiddleware) =>
//   getDefaultMiddleware();
// .concat(logger);

export const store = configureStore({
  reducer: {
    auth: loginReducer,
    app: appReducer,
    register: registerReducer,
    courseExam: courseExamReducer,
    getCoursesList: categoryListReducer,
    userPreference: userPreferenceReducer,
    notifications: notificationReducer,
    // filterContent: filterContentReducer,
    [advertisementApi.reducerPath]: advertisementApi.reducer,
    [universityInfoApi.reducerPath]: universityInfoApi.reducer,
    [courseListApi.reducerPath]: courseListApi.reducer,
    [userPreferenceApi.reducerPath]: userPreferenceApi.reducer,
    [questionSetApi.reducerPath]: questionSetApi.reducer,
    [entranceQuestionApi.reducerPath]: entranceQuestionApi.reducer,
    [leaderboardListApi.reducerPath]: leaderboardListApi.reducer,
    [discussionForumApi.reducerPath]: discussionForumApi.reducer,
    [discussionCommentApi.reducerPath]: discussionCommentApi.reducer,
    [myPerformanceApi.reducerPath]: myPerformanceApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [recentQuizApi.reducerPath]: recentQuizApi.reducer,
    [quizDetailsApi.reducerPath]: quizDetailsApi.reducer,
    [courseSubjectApi.reducerPath]: courseSubjectApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userPreferenceApi.middleware,
      advertisementApi.middleware,
      universityInfoApi.middleware,
      courseListApi.middleware,
      entranceQuestionApi.middleware,
      leaderboardListApi.middleware,
      userProfileApi.middleware,
      questionSetApi.middleware,
      notificationsApi.middleware,
      recentQuizApi.middleware,
      quizDetailsApi.middleware
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
