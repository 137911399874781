import Line from 'common/Line';
import ActivityNotfications from 'pages/PocketExamPage/MenuRoutesComponent/Dashboard/RightContent/Notification/ActivityNotifications';
import AllNotifications from 'pages/PocketExamPage/MenuRoutesComponent/Dashboard/RightContent/Notification/AllNotifications';
import React from 'react';
import { useState } from 'react';
// css
import './index.scss';

const NotificationHover = ({ handleClose }) => {
  const [tab, setTab] = useState('all');
  return (
    <div className='notificationHover'>
      <h5 className='notificationHover__title'>Notification</h5>
      <div className='notificationHover__toggleBtn'>
        <button
          className={`${tab === 'all' && 'active'}`}
          onClick={() => setTab('all')}
        >
          All
        </button>
        <button
          className={`${tab === 'activity' && 'active'}`}
          onClick={() => setTab('activity')}
        >
          Activity
        </button>
      </div>
      <Line orientation='horizontal' className='notificationHover__line' />

      {tab === 'all' && <AllNotifications />}

      {tab === 'activity' && <ActivityNotfications />}
    </div>
  );
};

export default NotificationHover;
