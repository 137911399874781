import Avatar from 'common/Avatar';
import React from 'react';
import './index.scss';

const MemberCard = ({ icon, total, text }) => {
  return (
    <div className='memberCard'>
      <Avatar src={icon} size='lg' />
      <div className='memberCard__details'>
        <h5>{total}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default MemberCard;
