import React from 'react';
import './index.scss';

import MyPurchaseBody from './MyPurchaseBody/MyPurchaseBody';
import HeaderSection from '../HeaderSection';

const purchaseData = [
  {
    id: 0,
    name: 'Course Certificate for Machine Learning',
    certifiedDate: 'Dec 9, 2020',
    amount: '599',
    purchaseDate: 'Aug 18, 2020',
  },
  {
    id: 1,
    name: 'Course Certificate for Machine Learning',
    certifiedDate: 'Dec 9, 2020',
    amount: '599',
    purchaseDate: 'Aug 18, 2020',
  },
];

function MyPurchases() {
  return (
    <div className='myPurchases'>
      <HeaderSection title='My Purchases' />
      <MyPurchaseBody data={purchaseData} />
    </div>
  );
}

export default MyPurchases;
