import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/baseQuery';

export const entranceQuestionApi = createApi({
  reducerPath: 'entranceQuestionApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getEntranceQuestions: build.query({
      query: ({ slug, university, model_slug }) => ({
        url: `/admin/get-web-test-question?slug=${slug}&university=${university}&model-slug=${model_slug}&institute=pocket-exam`,
        method: 'GET',
      }),
    }),
    postEntranceResult: build.mutation({
      query: ({ slug, university, model_slug, formdata }) => ({
        url: `/admin/get-web-test-question?slug=${slug}&university=${university}&model-slug=${model_slug}&institute=pocket-exam`,
        method: 'POST',
        body: formdata,
      }),
    }),
  }),
});

export const { useGetEntranceQuestionsQuery, usePostEntranceResultMutation } =
  entranceQuestionApi;
