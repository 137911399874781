import React, { memo } from 'react';
import { Link } from 'react-router-dom';
// css
import './index.scss';

const Breadcrumb = ({ pathname }) => {
  return (
    <div className='breadcrumb'>
      <Link to='/dashboard'>
        <p>Home</p>
      </Link>
      {pathname.map((path, index) => (
        <React.Fragment key={index}>
          <p className='breadcrumb__lineBreaker'>/</p>
          <p className='breadcrumb__path'>{path}</p>
        </React.Fragment>
      ))}
    </div>
  );
};

export default memo(Breadcrumb);
