import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(90deg, #0fa6ac 0%, #4bbd97 100%);
  padding-top: 80px;
  min-height: 145px;
  margin-bottom: 60px;
  .subHeader__container {
    margin: auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 38px 0;
  }
  .subHeader__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #ffffff;
    text-transform: capitalize;
  }
  .subHeader__date {
    margin-top: 8px;
    display: flex;
    div {
      padding-left: 8px;
      font-size: 15px;
      line-height: 26px;
      color: #ffffff;
      span {
        text-transform: capitalize;
        color: #ffffff;
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .subHeader__container {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
