import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/baseQuery';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAllNotifications: build.query({
      query: ({ slug, page }) => ({
        url: `/admin/notification?slug=${slug}&institute=pocket-exam&page=${page}`,
        method: 'GET',
      }),
    }),
    getActivityNotifications: build.query({
      query: ({ slug, page }) => ({
        url: `/admin/activity?page=${page}&institute=pocket-exam&slug=${slug}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetActivityNotificationsQuery, useGetAllNotificationsQuery } =
  notificationsApi;
