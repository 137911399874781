import Button from "common/Button";
import ModalBox from "common/Modal";
import Select from "common/Select";
import React, { createContext, useEffect, useState } from "react";
import NewTopicModal from "./NewTopicModal";
// css
import "./index.scss";
import { useGetSubjectQuery } from "app/services/courseSubjectApi";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";

const HeaderWithFiltering = ({ setsubjectSortBy, setpostSortBy }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  // const [selectSortBy, setSelectSortBy] = useState("");
  const { data: userPreference } = useSelector(userPreferenceSelector);

  const { data, isLoading } = useGetSubjectQuery(
    {
      slug: userPreference.course_slug,
      institute: "pocket-exam",
    },
    { refetchOnMountOrArgChange: 60 }
  );
  useEffect(() => {
    setsubjectSortBy(data?.CourseSubject[0].subject_name);
  }, [data?.CourseSubject, setsubjectSortBy]);

  return (
    <>
      <div className="headerWithFiltering">
        <h5>Forums</h5>
        <div className="headerWithFiltering__flex">
          <Select
            className="headerWithFiltering__flex--select"
            options={data?.CourseSubject}
            setValue={setsubjectSortBy}
          />

          <Button
            className="headerWithFiltering__flex--btn"
            size="sm"
            variant="contained"
            color="main"
            onClick={() => {
              setOpen(true);
            }}
          >
            + New Topic
          </Button>
        </div>
      </div>
      <ModalBox style={{ width: "100vw" }} open={open} setOpen={setOpen}>
        <NewTopicModal
          handleClose={handleClose}
          data={data?.CourseSubject}
          setpostSortBy={setpostSortBy}
        />
      </ModalBox>
    </>
  );
};

export default HeaderWithFiltering;
