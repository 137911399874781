import React from 'react';
import './index.scss';

const CurseDetailsTitle = ({ subtitle, title }) => {
  return (
    <div className='courseDetailsTitle'>
      <p>{subtitle}</p>
      <h1>{title}</h1>
    </div>
  );
};

export default CurseDetailsTitle;
