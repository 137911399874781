import React from "react";
// css
import "./index.scss";

function DiscussionDetailHeader({ data }) {
  return (
    <header className="discussionDetailHeader">
      {/* <h3>{data?.data.message}</h3> */}
      <p>{data?.data.tag}</p>
    </header>
  );
}

export default DiscussionDetailHeader;
