import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import "./index.scss";

const HeaderContainer = ({ title, subtitle, buttonText, arrow, style }) => {
  return (
    <div className="headerContainer">
      <div style={style} className="headerContainer__textWrapper">
        <h4>{title}</h4>
        {subtitle && <p>{subtitle}</p>}
      </div>
      {arrow && (
        <div className="headerContainer__btnWrapper">
          <Link to="/pocket-exam/questions-bank">
            <button>
              {buttonText}
              <IoIosArrowForward />
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default HeaderContainer;
