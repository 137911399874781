import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "app/baseQuery";

export const courseSubjectApi = createApi({
  reducerPath: "courseSubjectApi",
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getSubject: build.query({
      query: ({ slug, institute }) => ({
        url: `/admin/web-get-subject?slug=${slug}&institute=${institute}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetSubjectQuery } = courseSubjectApi;
