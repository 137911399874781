import React, { useState } from "react";
import replyWhiteIcon from "assets/icons/reply__icon.svg";
import ModalBox from "common/Modal";
import ReplyFormModal from "./ReplyFormModal";

const ReplyBtn = ({ postid,setcommentSortBy }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <button
        className="sharereplyreport__left--cyanBtn"
        onClick={() => {
          setOpen(true);
        }}
      >
        <img src={replyWhiteIcon} alt="reply-icon" />
        reply
      </button>
      <ModalBox
        style={{ width: "100vw" }}
        open={open}
        handleClose={handleClose}
      >
        <ReplyFormModal handleClose={handleClose} postid={postid} setcommentSortBy={setcommentSortBy}/>
      </ModalBox>
    </>
  );
};

export default ReplyBtn;
