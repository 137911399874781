import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "app/baseQuery";

export const leaderboardListApi = createApi({
  reducerPath: "leaderboardListApi",
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getLeaderboard: build.query({
      query: ({ slug, time, page, institute, testname }) => ({
        url: `/admin/all-test-leaderboard?slug=${slug}&time=${time}&page=${page}&institute=${institute}&testname=${testname}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetLeaderboardQuery } = leaderboardListApi;
