import React, { useContext } from 'react';
import Timer from './Timer';
import { CourseExamPageContext } from 'contextAPI/CourseExamPageContext';
// css
import './index.scss';

const QuestionContainerHeader = () => {
  const { userPreferenceData, subjectName } = useContext(CourseExamPageContext);

  return (
    <div className='questionContainerHeader'>
      <div className='questionContainerHeader__title'>
        <h4>{subjectName}</h4>
        <p>{userPreferenceData?.course_name}</p>
      </div>

      <Timer />
    </div>
  );
};

export default React.memo(QuestionContainerHeader);
