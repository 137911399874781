import React from 'react';
import './index.scss';

const NewTopicDetails = () => {
  return (
    <div className='newTopicDetails'>
      <p>
        Welcome to Pocket Academy Support Forum — thanks for starting a new
        conversation!
      </p>
      <div className='newTopicDetails__list'>
        <li>
          Does the title sound interesting if you read it out loud? Is it a good
          summary?
        </li>
        <li>
          Who would be interested in this? Why does it matter? What kind of
          responses do you want?
        </li>
      </div>
      <p>
        Include commonly used words in your topic so others can find it. To
        group your topic with related topics, select a category.
      </p>
    </div>
  );
};

export default NewTopicDetails;
