import Breadcrumb from "common/Breadcrumb";
import Container from "common/Container";
import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import tick from "assets/icons/tick.svg";
import EducationImage from "assets/courseImages/Education Agency.svg";
import Button from "common/Button";
import ModalBox from "common/Modal";
import EnrollNowModal from "./EnrollNowModal";
import { CourseDetailsContext } from "../index";
// css
import "./index.scss";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";

const list = [
  { id: 0, name: "Authentic Questions" },
  { id: 1, name: "100+ Questions" },
];

const FeaturedCourseSection = ({ data }) => {
  const { data: userPreferenceData } = useSelector(userPreferenceSelector);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <section className="featuredCourseSection">
      <Container>
        <Breadcrumb pathname={[userPreferenceData.course_name, data.name]} />
        <div className="featuredCourseSection__body">
          <div className="featuredCourseSection__body--left">
            <h1>{data.name}</h1>
            <p>
              Want to crack {userPreferenceData.course_name} with your dream
              score?
            </p>

            <div className="featuredCourseSection__body--left-listContainer">
              {list.map((list) => (
                <div key={list.id}>
                  <img src={tick} alt="tick-icon" />
                  <p>{list.name}</p>
                </div>
              ))}
            </div>

            {/* <h4>Go Premium Now to Save NPR 8,00/-</h4>
            <h4>Get 2 Months Extra Validity</h4> */}

            <div style={{ marginTop: "3rem" }}>
              <Button
                size="xl"
                variant="contained"
                color="main"
                onClick={handleOpen}
              >
                Start Quiz
              </Button>
            </div>
          </div>
          <div className="featuredCourseSection__body--right">
            {data?.banner != null ? (
              <img src={data?.banner} alt="main-featured-img" />
            ) : (
              <img src={EducationImage} alt="main-featured-img" />
            )}
          </div>
        </div>

        <ModalBox
          style={{ width: "50rem" }}
          open={open}
          handleClose={handleClose}
        >
          <EnrollNowModal
            data={data}
            userPreferenceData={userPreferenceData}
            handleClose={handleClose}
          />
        </ModalBox>
      </Container>
    </section>
  );
};

export default FeaturedCourseSection;
