import { Avatar as AvatarBox } from '@material-ui/core';
import classNames from 'classnames';
import { loginSelector } from 'pages/AuthenticationPage/Login/LoginSlice';
import React from 'react';
import { useSelector } from 'react-redux';
// css
import './index.scss';

const Avatar = ({ src, className, size }) => {
  const { data } = useSelector(loginSelector);
  let first_letter = data?.first_name?.charAt(0);

  return (
    <AvatarBox
      alt={`${data?.first_name}-avatar`}
      src={src}
      className={classNames(className, 'avatar', {
        [`avatar--${size}`]: size,
      })}
    >
      {src === null && first_letter}
    </AvatarBox>
  );
};

export default Avatar;
