import React from "react";
import GooglePlay from "assets/images/store/googleplay.svg";
import AppleStore from "assets/images/store/applestore.svg";
import FacebookIcon from "assets/icons/socialicons/facebook.svg";
import InstagramIcon from "assets/icons/socialicons/instagram.svg";
import TwitterIcon from "assets/icons/socialicons/twitter.svg";
import LinkedinIcon from "assets/icons/socialicons/linkedin.svg";
import Container from "common/Container";
// css
import "./index.scss";
import { Link } from "react-router-dom";
const FooterPocketExam = () => {
  const currentYear = new Date();
  return (
    <div className="footerPocketExam">
      <div className="footerPocketExam__imageContainer">
        <a href="https://play.google.com/store/apps/details?id=org.growthlabnepal.pocketexam">
          <img src={GooglePlay} alt="google-play-img" />
        </a>
        {/* <img src={AppleStore} alt="apple-store-img" /> */}
      </div>

      <p className="footerPocketExam__copyrightSection">
        Copyright &#169; {currentYear.getFullYear()} All rights reserved. |{" "}
        <span>Developed by Growth Lab Technology</span>
      </p>
      <div style={{ backgroundColor: "#fff" }}>
        <Container>
          <div className="footerPocketExam__bottomSection">
            <div className="footerPocketExam__bottomSection--left">
              <ul>
                <Link to="/aboutus">
                  <li>About us</li>
                </Link>
                {/* <li>FAQs</li> */}
                <Link to="/termsandconditions">
                  <li>terms and conditions</li>
                </Link>
                <Link to="/privacy-policy">
                  <li>privacy policy</li>
                </Link>
                {/* <li>contact us</li> */}
              </ul>
            </div>

            <div className="footerPocketExam__bottomSection--right">
              <a href="https://www.facebook.com/pocketexam.np">
                <div>
                  <img src={FacebookIcon} alt="facebook-icon" />
                </div>
              </a>
              {/* <div>
                <img src={InstagramIcon} alt="instagram-icon" />
              </div>
              <div>
                <img src={TwitterIcon} alt="twitter-icon" />
              </div>
              <div>
                <img src={LinkedinIcon} alt="linkedin-icon" />
              </div> */}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default FooterPocketExam;
