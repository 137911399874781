import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useGetCommentQuery } from "app/services/discussionCommentApi";
import useQuery from "hooks/useQuery";
import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import HeaderSection from "../HeaderSection";
import ExamDiscussionDetail from "./ExamDiscussionDetail";
import ExamDiscussionTable from "./ExamDiscussionTable";
import HeaderWithFiltering from "./HeaderWithFiltering";
// css
import "./index.scss";
import { useHistory, useLocation } from "react-router-dom";

export const ExamDiscussionContext = createContext("All");
function ExamDiscussion(props) {
  let query = useQuery();
  let isSlugInQuery = query.has("id");
  const { state } = useLocation();
  const [selectType, setSelectType] = useState("");
  const [selectSortBy, setSelectSortBy] = useState("");
  const [subjectSortBy, setsubjectSortBy] = useState("");
  const [postSortBy, setpostSortBy] = useState(null);
  const { data: userPreference } = useSelector(userPreferenceSelector);
  return (
    <div className="exam_discussion">
      <HeaderSection
        title="exam discussion"
        isExamDiscussion
        data={state}
        setSelectType={setSelectType}
        setSelectSortBy={setSelectSortBy}
      />

      {isSlugInQuery ? (
        <ExamDiscussionDetail query={query} data={state} />
      ) : (
        <ExamDiscussionContext.Provider
          value={{ userPreference, subjectSortBy }}
        >
          <HeaderWithFiltering
            setsubjectSortBy={setsubjectSortBy}
            setpostSortBy={setpostSortBy}
          />
          <ExamDiscussionTable response={postSortBy} />
        </ExamDiscussionContext.Provider>
      )}
    </div>
  );
}

export default ExamDiscussion;
