import { loginSelector } from 'pages/AuthenticationPage/Login/LoginSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useSelector(loginSelector);

  if (isAuthenticated) {
    return <Redirect to='/pocket-exam/dashboard' />;
  }

  return children;
};

export default PublicRoute;
