import { useEffect } from 'react';

const useScrollToTop = (value) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [value]);
};

export default useScrollToTop;
