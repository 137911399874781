import CircularProgress from 'common/CircularProgress';
import Line from 'common/Line';
import React, { useState } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useFetch from './useFetch';
import AllNotificationCard from './AllNotificationCard';
import './index.scss';

const AllNotifications = () => {
  const [page, setPage] = useState(1);
  const { isFetching, error, list, data } = useFetch(page);

  useIntersectionObserver({
    querySelector: '.notification_body',
    list,
    next: data?.next,
    page,
    setPage,
  });

  return (
    <div className='allnotification'>
      {list?.map((notification) => (
        <React.Fragment key={notification.id}>
          <AllNotificationCard
            id={notification.id}
            notification={notification}
          />

          <Line orientation='horizontal' className='notification__line' />
        </React.Fragment>
      ))}
      {isFetching && <CircularProgress size='md' />}
      {error && <p>{error}</p>}
    </div>
  );
};

export default AllNotifications;
