import { useMediaQuery } from '@material-ui/core';
import Container from 'common/Container';
import Line from 'common/Line';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormContainer from '../FormContainer';
import SocialAuth from '../SocialAuth';
import LoginForm from './LoginForm';
import { loginSelector } from './LoginSlice';

const Login = () => {
  const isTabletScreen = useMediaQuery('(max-width: 768px)');
  const history = useHistory();

  const { data, isAuthenticated } = useSelector(loginSelector);

  useEffect(() => {
    if (data.status_code === 202 || isAuthenticated) {
      history.push('/pocket-exam/dashboard');
    }
  }, [data, isAuthenticated, history]);

  return (
    <Container>
      <div style={{ paddingTop: '5rem' }}>
        <FormContainer>
          <SocialAuth />
          <Line orientation={isTabletScreen ? 'horizontal' : 'vertical'} />
          <LoginForm />
        </FormContainer>
      </div>
    </Container>
  );
};

export default Login;
