import React from 'react';
import './index.scss';

const FormContainer = ({ style, children }) => {
  return (
    <div style={style} className='form-box'>
      {children}
    </div>
  );
};

export default FormContainer;
