import BodyContainer from "common/BodyContainer";
import { AboutUsBanner, BenefitsForOurLearners } from "components/AboutUs";

import React, { useEffect } from "react";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: "0" });
  }, []);

  return (
    <BodyContainer>
      <AboutUsBanner />
      {/* Enjoyable Benefits for Our Learners. */}
      <BenefitsForOurLearners />
    </BodyContainer>
  );
};

export default AboutUsPage;
