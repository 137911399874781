import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useGetEntranceQuestionsQuery } from "app/services/entranceQuestionApi";
import useQuery from "hooks/useQuery";
import {
  courseExamSelector,
  resetAnswer,
} from "pages/CourseExamPage/courseExamSlice";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const CourseExamPageContext = createContext(null);

const CourseExamPageProvider = ({ children }) => {
  const [subjectName, setSubjectName] = useState("");
  const history = useHistory();
  let query = useQuery();
  const dispatch = useDispatch();

  const slug = query?.get("slug");
  const university = query?.get("university");
  const model_slug = query?.get("model_slug");

  const { data: questionsList } = useGetEntranceQuestionsQuery({
    slug,
    university,
    model_slug,
  });

  const { data: userPreferenceData } = useSelector(userPreferenceSelector);
  const { answers } = useSelector(courseExamSelector);
  const totalAnswered = useMemo(() => answers?.length, [answers]);
  const total_questions = useMemo(
    () => questionsList?.total_question,
    [questionsList]
  );
  const questionsListMemoized = useMemo(() => questionsList, [questionsList]);
  const calculateTotalNotAnswered = useCallback(() => {
    return total_questions - answers?.length;
  }, [answers, total_questions]);

  useEffect(() => {
    if (userPreferenceData?.course_slug !== slug) {
      history.push("/pocket-exam/questions-bank");
    }
  }, [userPreferenceData?.course_slug, history, slug]);

  useEffect(() => dispatch(resetAnswer()), [dispatch]);

  const value = useMemo(
    () => ({
      answers,
      totalAnswered,
      total_questions,
      questions: questionsListMemoized,
      calculateTotalNotAnswered,
      userPreferenceData,
      setSubjectName,
      subjectName,
      slug,
      university,
      model_slug,
    }),
    [
      answers,
      totalAnswered,
      total_questions,
      questionsListMemoized,
      calculateTotalNotAnswered,
      userPreferenceData,
      setSubjectName,
      subjectName,
      slug,
      university,
      model_slug,
    ]
  );

  return (
    <CourseExamPageContext.Provider value={value}>
      {children}
    </CourseExamPageContext.Provider>
  );
};

export default React.memo(CourseExamPageProvider);
