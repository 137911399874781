import React, { useContext, useEffect, useMemo, useState } from 'react';
import useScrollToTop from 'hooks/useScrollToTop';
import QuestionQuizCard from 'pages/CourseExamPage/QuestionsContainer/QuestionQuizCard';
import { ReviewAnswerContext } from '../index';
import classNames from 'classnames';
import Pagination from 'common/Pagination';
import { FilterMediaRoutePattern } from 'utils/regex';
import MathJax from 'mathjax3-react';
// css
import './index.scss';

const QuestionsContainer = () => {
  const { data } = useContext(ReviewAnswerContext);
  const items = data?.data;
  // console.log(items);
  const itemsPerPage = 20;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useScrollToTop(itemOffset);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const memoizedPageCount = useMemo(() => pageCount, [pageCount]);

  let answerImgPath;
  let isAnswerImgPath;
  const answerWithImage = (answer) => {
    isAnswerImgPath = answer.match(FilterMediaRoutePattern);
    answerImgPath = `${URL}${answer.match(FilterMediaRoutePattern)}`;
    // console.log('is answer path', isAnswerImgPath);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };
  return (
    <MathJax.Provider
      url='https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js'
      options={{
        tex: {
          inlineMath: [
            ['$', '$'],
            ['\\(', '\\)'],
          ],
        },
      }}
    >
      <div className='questions-container'>
        {currentItems?.map((question) => (
          <div
            key={question.id}
            className={classNames('questions-container__wrapper', {
              attend: question.is_attend,
              unattend: !question.is_attend,
              correct: question.is_attend && question.is_correct,
            })}
          >
            <QuestionQuizCard data={question} resultcard={true} />

            {question.optiona === 'null'
              ? null
              : !question.is_correct && (
                  <>
                    <h4 className='questions-container__correctAnswer'>
                      <small>*</small>
                      <h4>Correct answer is</h4>{' '}
                      <MathJax.Formula
                        formula={question.answer.replace(
                          FilterMediaRoutePattern,
                          ''
                        )}
                      />
                    </h4>
                    {answerWithImage(question.answer)}
                    {/* will remove image path but will show other string */}
                    {isAnswerImgPath !== null && (
                      <img
                        src={answerImgPath}
                        alt={`question-${data.question_no}`}
                      />
                    )}
                  </>
                )}
          </div>
        ))}
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={memoizedPageCount}
        />
      </div>
    </MathJax.Provider>
  );
};

export default QuestionsContainer;
