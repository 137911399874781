import Button from 'common/Button';
import Container from 'common/Container';
import React from 'react';
import FormContainer from '../FormContainer';
import SuccessImg from 'assets/images/sociallogin/success.svg';
import { useHistory } from 'react-router-dom';
// css
import './index.scss';

const RegistrationComplete = () => {
  const history = useHistory();
  return (
    <Container>
      <div style={{ paddingTop: '5rem' }}>
        <FormContainer style={{ maxWidth: '68rem', margin: 'auto' }}>
          <div className='registrationComplete'>
            <img src={SuccessImg} alt='success-icon' />
            <h1>Registration completed Succesfully.</h1>
            <Button
              style={{ width: '20rem' }}
              color='gradient'
              size='md'
              variant='contained'
              onClick={() => history.push('/login')}
            >
              Continue
            </Button>
          </div>
        </FormContainer>
      </div>
    </Container>
  );
};

export default RegistrationComplete;
