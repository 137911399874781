import HeaderContainer from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/HeaderContainer";
import React, { useContext } from "react";
import { DashboardContext } from "../../Dashboard";
import "./index.scss";

const HeaderContent = () => {
  const { course_name, full_name } = useContext(DashboardContext);

  return (
    <div className="headerContent">
      <HeaderContainer title={course_name} subtitle={full_name} />
    </div>
  );
};

export default HeaderContent;
