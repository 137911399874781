import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/baseQuery';

export const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  baseQuery: baseQuery,
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getUserProfile: build.query({
      query: () => ({
        url: `/student/student-profile/`,
        method: 'GET',
      }),
      providesTags: ['Profile'],
    }),
    updateUserProfile: build.mutation({
      query: (body) => ({
        url: '/student/student-profile/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export const { useGetUserProfileQuery, useUpdateUserProfileMutation } =
  userProfileApi;
