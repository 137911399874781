import { userPreferenceSelector } from 'app/features/userPreferenceSlice';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActivityNotifications,
  notificationsSelector,
} from '../NotificationsSlice';

function useFetch(page) {
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);

  const { data: userPreference } = useSelector(userPreferenceSelector);
  const { data, isFetching } = useSelector(notificationsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getActivityNotifications({ page, slug: userPreference?.course_slug })
    )
      .then(async (data) => {
        // console.log('activity data', data);
        const notifications = [];
        await data?.payload?.data?.forEach((p) => notifications.push(p));
        await setList((prev) => prev.concat(notifications));
      })
      .catch((err) => setError(err));
  }, [dispatch, page, userPreference]);

  return { isFetching, error, list, data };
}

export default useFetch;
