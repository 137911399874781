import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BsCheckLg } from "react-icons/bs";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  AiFillCopy,
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
import CancelButton from "common/CancelButton";
// css
import "./index.scss";

const ShareHover = ({ handleClose, postid }) => {
  const [value, setValue] = useState(
    `https://www.pocketexam.co/pocket-exam/exam-discussion?id=${postid}`
  );
  const [copied, setCopied] = useState(false);

  const title = "GitHub";

  return (
    <div className="shareHover">
      <div className="shareHover__header">
        <h4>Share</h4>

        <CancelButton
          className="shareHover__header--btn"
          onClick={handleClose}
        />
      </div>

      <div className="shareHover__body">
        <input value={value} disabled />

        <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
          {copied ? (
            <div className="shareHover__body--checkIcon">
              <BsCheckLg />
            </div>
          ) : (
            <div className="shareHover__body--regCopyIcon">
              <AiFillCopy />
            </div>
          )}
        </CopyToClipboard>
      </div>

      <div className="shareHover__icons">
        <FacebookShareButton
          url={value}
          quote={title}
          className="shareHover__icons--socialicon"
        >
          <AiFillFacebook />
        </FacebookShareButton>

        <TwitterShareButton
          url={value}
          title={title}
          className="shareHover__icons--socialicon"
        >
          <AiOutlineTwitter />
        </TwitterShareButton>

        <LinkedinShareButton
          url={value}
          className="shareHover__icons--socialicon"
        >
          <AiFillLinkedin />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default ShareHover;
