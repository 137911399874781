import classNames from 'classnames';
import React from 'react';
import { ImCross } from 'react-icons/im';
// css
import './index.scss';

const CancelButton = ({ onClick, className }) => {
  return (
    <button className={classNames(className, 'cancelButton')} onClick={onClick}>
      <ImCross />
    </button>
  );
};

export default CancelButton;
