import React, { useState } from "react";
import InputContainer from "./InputContainer";
import NewTopicDetails from "./NewTopicDetails";
import CancelButton from "common/CancelButton";
// css
import "./index.scss";

const NewTopicModal = ({ handleClose, data, setpostSortBy }) => {
  return (
    <div className="newTopicModal">
      <div className="newTopicModal__body">
        <InputContainer
          handleClose={handleClose}
          selectdata={data}
          setpostSortBy={setpostSortBy}
        />

        <NewTopicDetails />
      </div>
      <div className="newTopicModal__cancelBtn">
        <CancelButton onClick={handleClose} />
      </div>
    </div>
  );
};

export default NewTopicModal;
