import Avatar from "common/Avatar";
import React from "react";
import profilepp from "assets/profile/pp.svg";
import renderHTML from "react-render-html";
import redFavIcon from "assets/icons/fav__red.svg";

// css
import "./index.scss";

function CommentCard({ comments }) {
  return (
    <div className="commentCard">
      <Avatar size="md-l" src={profilepp} />

      <div className="commentCard__info">
        <div className="commentCard__info--header">
          <h4>{comments.comment_by.username}</h4>
          <p>{comments.comment_at}</p>
        </div>
        <p className="commentCard__info--body">
          {renderHTML(comments.message)}
        </p>

        {/* <button className="commentCard__info--btn">
          <img src={redFavIcon} alt="fav-icon" />
          <span>(2)</span>
        </button> */}
      </div>
    </div>
  );
}

export default CommentCard;
