import classNames from "classnames";
import CircularProgress from "common/CircularProgress";
import React from "react";
// css
import "./index.scss";

const Select = ({ options, className, size, isLoading, setValue }) => {
  const handleSelect = (e) => {
    setValue(e.target.value);
  };

  if (isLoading) {
    return <CircularProgress size="xs" />;
  }
  return (
    <select
      className={classNames(className, "select-dropdown", {
        [`select--${size}`]: size,
      })}
      onChange={handleSelect}
    >
      {options?.map((option) => (
        <option key={option.id} value={option.subject_name}>
          {option.subject_name || option.universityTag}
        </option>
      ))}
    </select>
  );
};

export default Select;
