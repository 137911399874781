import React, { useState, useEffect } from "react";
import down_icon from "assets/icons/down_icon.svg";
import CategoryModal from "./CategoryModal/CategoryModal";
import ModalBox from "common/Modal";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useSelector } from "react-redux";
// css
import "./Category.css";

function Category() {
  const { data } = useSelector(userPreferenceSelector);
  // const isOpen = data?.is_found ? true : false;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (data.is_found === false) {
      setOpen(true);
    }
  }, [data.is_found]);
  return data.length === 0 ? (
    <div />
  ) : (
    <>
      <div className="pocketExam__category">
        <button
          onClick={() => {
            setOpen(true);
          }}
        >
          <p>{data?.course_name}</p>
          <img src={down_icon} alt="icon" />
        </button>
      </div>

      <ModalBox
        style={{ width: "61.4rem" }}
        open={open}
        handleClose={handleClose}
      >
        <CategoryModal handleClose={handleClose} checkData={data} />
      </ModalBox>
    </>
  );
}

export default Category;
