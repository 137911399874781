import Avatar from 'common/Avatar';
import Button from 'common/Button';
import Line from 'common/Line';
import { loginSelector } from 'pages/AuthenticationPage/Login/LoginSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// css
import './index.scss';

const UserProfile = ({ handleClose }) => {
  const { isAuthenticated, data } = useSelector(loginSelector);
  // const user = false;
  if (!isAuthenticated) {
    return (
      <div className='userProfile'>
        <Button
          style={{ width: '100%' }}
          color='gradient'
          variant='contained'
          size='lg'
        >
          Login/sign up
        </Button>
      </div>
    );
  }
  return (
    <div className='userProfile'>
      {/* <Avatar large='true' /> */}
      <Avatar src={data?.image_uri} size='lg' />

      <div className='userProfile__userInfo'>
        <h4>aakash shrestha</h4>
        <h5>aakashshrestha@gmail.com</h5>
        <div className='userProfile__userInfo--profile'>
          <Link to='/'>
            <p onClick={handleClose}>view profile</p>
          </Link>
          <Line orientation='vertical' />
          <Link to='/'>
            <p onClick={handleClose}>edit profile</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
