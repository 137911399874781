import Container from 'common/Container';
import Line from 'common/Line';
import React, { createContext, useEffect } from 'react';
import QuestionsContainer from './QuestionsContainer';
import ReviewAnswerHeader from './ReviewAnswerHeader';
import { useHistory, useLocation } from 'react-router-dom';
import useScrollToTop from 'hooks/useScrollToTop';
import { useSelector } from 'react-redux';
import { userPreferenceSelector } from 'app/features/userPreferenceSlice';
// css
import './index.scss';

export const ReviewAnswerContext = createContext(null);

const ReviewAnswerPage = () => {
  const { data: userPreferenceData } = useSelector(userPreferenceSelector);
  useScrollToTop();
  const { state } = useLocation();
  const history = useHistory();
  // console.log('state', state);
  const { data, slug } = state;

  useEffect(() => {
    if (userPreferenceData?.course_slug !== slug) {
      history.push('/pocket-exam/questions-bank');
    }
  }, [userPreferenceData?.course_slug, history, slug]);

  return (
    <ReviewAnswerContext.Provider value={{ data, slug }}>
      <Container>
        <div id='quiz-container' className='reviewAnswerPage'>
          <div className='reviewAnswerPage__body'>
            <ReviewAnswerHeader />
            <Line orientation='horizontal' />
            <QuestionsContainer />
          </div>
        </div>
      </Container>
    </ReviewAnswerContext.Provider>
  );
};

export default ReviewAnswerPage;
