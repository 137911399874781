import Button from "common/Button";
import Input from "common/Input/Input";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginSelector, loginUser } from "./LoginSlice";
import ErrorMessage from "common/ErrorMessage";
// css
import "./index.scss";

const LoginForm = () => {
  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required."),
    password: Yup.string()
      .min(8, "Must be 8 characters or more")
      .max(32, "Must not be more than 32 characters")
      .required("Password is required."),
  });
  const {
    control,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const { isFetching, errorMessage, error } = useSelector(loginSelector);

  const onSubmit = (data) => {
    const { email, password } = data;
    dispatch(loginUser({ email, password, device_id: "", type: "custom" }));
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <div className="loginform">
      <h1>Log In to your Pocket Exam Account!</h1>

      <form
        autoComplete="off"
        className="loginform__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="email"
          type="email"
          size="md"
          placeholder="Email"
          control={control}
          errors={errors?.email}
        />

        <Input
          style={{ marginTop: "2.4rem" }}
          name="password"
          type="password"
          size="md"
          placeholder="Password"
          control={control}
          errors={errors?.password}
        />
        {error && <ErrorMessage>{errorMessage?.message}</ErrorMessage>}

        <h6 className="loginform__form--forgotPassword">
          {/* <Link to="/forgot-password">Forgot password?</Link> */}
        </h6>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          size="md"
          color="gradient"
          type="submit"
          isLoading={isFetching}
        >
          Log In
        </Button>
      </form>
      <p className="loginform__terms">
        By signing up, you agree to our
        <span>
          <Link to="/termsandconditions"> Terms of Use</Link>
        </span>{" "}
        and
        <span>
          {" "}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </span>
      </p>
      <h2>
        Don’t have an account?{" "}
        <Link to="/register">
          <span>Sign up</span>
        </Link>
      </h2>
    </div>
  );
};

export default LoginForm;
