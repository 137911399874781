import Avatar from "common/Avatar";
import React from "react";
// css
import "./index.scss";
import { Avatar as AvatarBox } from "@material-ui/core";
import classNames from "classnames";
const LeaderboardTable = ({ dat, leaderboardmodal, className }) => {
  return (
    <table className={`leaderboardTable ${leaderboardmodal && "modal"}`}>
      {leaderboardmodal ? null : (
        <thead>
          <tr>
            <th>Rank</th>
            <th>name & scope</th>
            <th>CORRECT Q</th>
          </tr>
        </thead>
      )}

      {dat?.leaderboard
        .slice(0, leaderboardmodal ? dat?.leaderboard.length : 8)
        .map((leaderboard, index) => (
          <tbody key={leaderboard.user_id}>
            <tr>
              <td>{index + 1}</td>
              <td>
                <div className="leaderboardTable__td--namescope">
                  {/* <Avatar size="lg" /> */}
                  <AvatarBox
                    alt={`${leaderboard.username}-avatar`}
                    src={leaderboard.image_url}
                    className={classNames(className, "avatar", {
                      [`avatar--lg`]: "lg",
                    })}
                  >
                    {leaderboard.image_url === "" &&
                      leaderboard.username.charAt(0)}
                  </AvatarBox>
                  <div>
                    <h5>{leaderboard.username}</h5>
                    {/* <p>146.6/200</p> */}
                  </div>
                </div>
              </td>
              <td className="leaderboardTable__td--attemptedQuestions">
                {leaderboard.correct}
              </td>
            </tr>
          </tbody>
        ))}
    </table>
  );
};

export default LeaderboardTable;
