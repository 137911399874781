import Container from 'common/Container';
import React from 'react';
import ContentContainer from './ContentContainer';
import Sidebar from './Sidebar';
import './index.scss';

const PocketExamPage = () => {
  return (
    <Container>
      <div className='pocketexam'>
        <Sidebar />
        <ContentContainer />
      </div>
    </Container>
  );
};

export default PocketExamPage;
