import Container from 'common/Container';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FormContainer from '../FormContainer';
import ChangePasswordForm from './ChangePasswordForm';
// css
import './index.scss';

const ChangePassword = () => {
  const [success, setSuccess] = useState(false);

  const changeSuccess = () => {
    setSuccess(true);
  };

  return (
    <Container>
      <div style={{ paddingTop: '5rem' }}>
        <FormContainer style={{ maxWidth: '50rem', margin: 'auto' }}>
          {success ? (
            <div className='success-route'>
              <p>
                Password changed. You can{' '}
                <Link style={{ color: '#111111' }} to='/login'>
                  login
                </Link>{' '}
                now.
              </p>
            </div>
          ) : (
            <div className='changepassword'>
              <h1>Change password</h1>
              <ChangePasswordForm changeSuccess={changeSuccess} />
              <p>
                Hint: The password should be at least twelve characters long. To
                make it stronger, use upper and lower case letters, numbers, and
                symbols like ! " ? $ % ^ & ).
              </p>
            </div>
          )}
        </FormContainer>
      </div>
    </Container>
  );
};

export default ChangePassword;
