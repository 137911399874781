import React from "react";
import { Link, useLocation } from "react-router-dom";
import { sidebarData } from "./sidebar.data";
import Line from "common/Line";
// css
import "./index.scss";

const Sidebar = () => {
  const location = useLocation();
  return (
    <div className="sidebar">
      <ul>
        {sidebarData.map((sidebar) => (
          <React.Fragment key={sidebar.id}>
            <Link to={sidebar.link}>
              <li
                className={`${sidebar.link === location.pathname && "active"}`}
              >
                {sidebar.icon} <p>{sidebar.name}</p>
              </li>
            </Link>
            <Line orientation="horizontal" />
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
