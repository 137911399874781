import HeaderContainer from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/HeaderContainer";
import React from "react";

// css
import "./index.scss";

function MyPerformance({ data }) {
  return (
    <div className="performance">
      <HeaderContainer title="My Performance" />

      <div className="performance__body">
        <div className="performance__body--score">
          <h5>{data?.score}</h5>
          <p>Overall Score</p>
        </div>
        <div className="performance__body--rank">
          <h5>{data?.position}</h5>
          <p>Pocket Ranking</p>
        </div>
      </div>
    </div>
  );
}

export default MyPerformance;
