import React, { createContext, useEffect, useMemo } from "react";
import DreamCourseFeatures from "./DreamCourseFeatures";
import FeaturedCourseSection from "./FeaturedCourseSection";
import LeaderboardSection from "./LeaderboardSection";
import IOMEssentialSection from "./IOMEssentialSection";
// css
import "./index.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import useScrollToTop from "hooks/useScrollToTop";
import useQuery from "hooks/useQuery";
import { useGetQuizDetailsQuery } from "app/services/quizDetailsApi";
import CircularProgress from "common/CircularProgress";
import { Redirect } from "react-router-dom";
export const CourseDetailsContext = createContext(null);

function CourseDetailsPage() {
  let query = useQuery();
  const { data, isLoading } = useGetQuizDetailsQuery(
    {
      courseslug: query.get("course"),
      slug: query.get("slug"),
      university: query.get("type"),
    },
    {}
  );

  // const { state } = useLocation();

  useScrollToTop();
  // const history = useHistory();
  // useEffect(() => {
  //   console.log("error data is", error);
  //   console.log("data is", data);
  //   if (data === "{}") {
  //     history.push("/pocket-exam/questions-bank");
  //   }
  // }, [data]);

  if (isLoading) {
    return <CircularProgress size="lg" />;
  }

  // const data = useMemo(() => ({ data: state.data }), [state.data]);

  // useEffect(() => {
  //   console.log("data is", data, isFetching);
  // }, [data, isFetching]);

  return Object.keys(data).length === 0 ? (
    <Redirect to="/pocket-exam/questions-bank" />
  ) : (
    <div className="courseDetails">
      <FeaturedCourseSection data={data} />
      <div style={{ backgroundColor: "#fff" }}>
        <DreamCourseFeatures />
      </div>
      <LeaderboardSection data={data} />
      <div style={{ backgroundColor: "#fff" }}>
        {/* <IOMEssentialSection /> */}
      </div>
    </div>
  );

  // return <div />;
}

export default CourseDetailsPage;
