import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "common/Avatar";
import Button from "common/Button";
import ProfileHover from "./ProfileHover";
import MenuDropdown from "common/MenuDropdown";
import { HeaderContext } from "../index";
// css
import "./Profile.scss";

function Profile() {
  const { isAuthenticated, userInfo } = useContext(HeaderContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!isAuthenticated) {
    return (
      <div className="profile">
        <Button
          style={{ padding: "1em" }}
          variant="contained"
          color="gradient"
          size="md"
          onClick={() => history.push("/login")}
        >
          Login/sign up
        </Button>
      </div>
    );
  }

  return (
    <div className="profile">
      <div className="profile__img" onClick={handleOpen} ref={anchorRef}>
        <Avatar src={userInfo?.image_uri} size="lg" />
      </div>

      <MenuDropdown
        profile="true"
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
      >
        <ProfileHover data={userInfo} handleClose={handleClose} />
      </MenuDropdown>
    </div>
  );
}

export default Profile;
