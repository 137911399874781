import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 100px;
  font-family: 'Poppins';
  .policy__title {
    margin-bottom: 18px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
  }

  li {
    list-style-position: inside;

    &::marker {
      font-size: 16px;
      line-height: 24px;
      color: #111111;
      font-family: Open Sans;
    }
  }

  ol {
    padding: 0;
  }

  .policy__list1,
  .policy__list2,
  .policy__list3 {
    font-size: 16px;
    line-height: 24px;
    color: #7a7a7a;

    dd {
      margin-left: 40px;
      margin-top: -24px;
      width: 800px;

      span {
        font-weight: 600;
        color: #4bbd97;
      }
    }
  }

  .policy__list2 {
    margin: 24px 0;
  }
  .policy__list3 {
    margin-bottom: 40px;
  }

  .policy__subscriberNotice {
    margin-left: 40px;
    width: 781px;
    background: #f4f4f4;
    padding: 22px 24px;
    margin-top: -16px;
    margin-bottom: 40px;

    p {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-bottom: 0;
    }
  }

  .policy__titleDefinitions {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
    margin-top: 50px;
    margin-bottom: 18px;
  }

  .policy__definitionsSubTitleTab {
    width: 100%;
    margin: 12px 0;
    width: 814.69px;
  }

  .policy__definitionsTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #111111;
  }

  .policy__definitionsContent {
    font-size: 16px;
    line-height: 24px;
    color: #7a7a7a;
    margin-left: 6px;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    .policy__list1,
    .policy__list2,
    .policy__list3 {
      dd {
        width: 90%;
      }
    }

    .policy__subscriberNotice {
      width: 90%;
    }

    .policy__definitionsSubTitleTab {
      width: 100%;
    }
  }
`;
