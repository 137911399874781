import { consts } from 'react-elastic-carousel';
import rightArrow from 'assets/icons/icon-right.svg';
import leftArrow from 'assets/icons/icon-left.svg';

export const myArrow = ({ type, onClick, isEdge }) => {
  const pointer =
    type === consts.PREV ? (
      <img
        style={{
          position: 'relative',
          left: '-10px',
          zIndex: '50',
          width: '4rem',
          height: '4rem',
        }}
        src={leftArrow}
        alt='left-arrow'
      />
    ) : (
      <img
        style={{
          position: 'relative',
          right: '3rem',
          zIndex: '50',
          width: '4rem',
          height: '4rem',
        }}
        src={rightArrow}
        alt='right-arrow'
      />
    );
  return (
    <button
      style={{
        position: 'relative',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        display: isEdge && 'none',
        width: 0,
      }}
      onClick={onClick}
      disabled={isEdge}
    >
      {pointer}
    </button>
  );
};

export const breakPointsForYoutubeSlider = [
  { width: 1, itemsToShow: 1 },
  { width: 500, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 800, itemsToShow: 2 },
  { width: 850, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
  { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 3 },
  { width: 1750, itemsToShow: 3 },
];
export const breakPoint = [
  { width: 1, itemsToShow: 1 },
  { width: 500, itemsToShow: 2 },
  { width: 550, itemsToShow: 2 },
  { width: 800, itemsToShow: 3 },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 4 },
  { width: 1750, itemsToShow: 4 },
];
export const breakPointsForAdvertisementSlider = [
  { width: 1, itemsToShow: 1 },
  { width: 500, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 800, itemsToShow: 1 },
  { width: 850, itemsToShow: 1 },
  { width: 1150, itemsToShow: 1 },
  { width: 1450, itemsToShow: 1 },
  { width: 1750, itemsToShow: 1 },
];
