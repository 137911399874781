import React from "react";
import { Route, Switch } from "react-router";
import { routes } from "../routes";
import "./index.scss";

const ContentContainer = () => {
  return (
    <div className="middlecontainer">
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.content />}
          />
        ))}
      </Switch>
    </div>
  );
};

export default ContentContainer;
