import Breadcrumb from 'common/Breadcrumb';
import { CourseExamPageContext } from 'contextAPI/CourseExamPageContext';
import React, { useContext, useMemo } from 'react';

const CourseExamBreadcrumb = () => {
  const { userPreferenceData, subjectName } = useContext(CourseExamPageContext);
  const memoizedBreadcrumb = useMemo(
    () => [userPreferenceData?.course_name, subjectName],
    [userPreferenceData?.course_name, subjectName]
  );
  return <Breadcrumb pathname={memoizedBreadcrumb} />;
};

export default CourseExamBreadcrumb;
