import Container from 'common/Container';
import React from 'react';
import QuestionsContainer from './QuestionsContainer';
import TimerSection from './TimerSection';
import CourseExamFooter from './CourseExamFooter';
import { useMediaQuery } from '@material-ui/core';
import { useGetEntranceQuestionsQuery } from 'app/services/entranceQuestionApi';
import useQuery from 'hooks/useQuery';
import CircularProgress from 'common/CircularProgress';
import ShowTimerForMediumScreen from './ShowTimerForMediumScreen';
import CourseExamPageProvider from 'contextAPI/CourseExamPageContext';
import CourseExamBreadcrumb from './CourseExamBreadcrumb';
// css
import './index.scss';

const CourseExamPage = () => {
  const isMediumScreen = useMediaQuery('(max-width:768px)');

  let query = useQuery();
  const slug = query?.get('slug');
  const university = query?.get('university');
  const model_slug = query?.get('model_slug');

  const { isLoading } = useGetEntranceQuestionsQuery({
    slug,
    university,
    model_slug,
  });

  if (isLoading) {
    return <CircularProgress size='lg' />;
  }

  return (
    <CourseExamPageProvider>
      {isMediumScreen && <ShowTimerForMediumScreen />}
      <Container>
        <div id='quiz-container' className='courseExamPage'>
          <CourseExamBreadcrumb />
          <div className='courseExamPage__body'>
            <div className='courseExamPage__body--left'>
              <QuestionsContainer />
            </div>
            <div className='courseExamPage__body--right'>
              <TimerSection />
            </div>
          </div>
        </div>
      </Container>
      <CourseExamFooter />
    </CourseExamPageProvider>
  );
};

export default React.memo(CourseExamPage);
