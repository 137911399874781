import { useGetAdvertisementQuery } from 'app/services/advertisementApi';
import CarouselSlider from 'common/Carousel';
import React, { useContext } from 'react';
import { DashboardContext } from '../../Dashboard';
// css
import './index.scss';

const AdvertisementSection = () => {
  const { course_slug } = useContext(DashboardContext);
  const { data, isFetching } = useGetAdvertisementQuery(
    {
      slug: course_slug,
    },
    { refetchOnMountOrArgChange: 60 }
  );

  return (
    <section className={`advertisementsection ${isFetching ? 'disabled' : ''}`}>
      <CarouselSlider isAdvertisement itemsToShow={1}>
        {data?.data?.map((data) => (
          <div className='advertisementsection__container' key={data.slug}>
            <img src={data.uploadedImage} alt={`${data.slug}-img`} />
          </div>
        ))}
      </CarouselSlider>
    </section>
  );
};

export default AdvertisementSection;
