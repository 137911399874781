import React from "react";
import memberIcon from "assets/icons/member-icon.svg";
import capIcon from "assets/icons/cap-icon.svg";
import Line from "common/Line";
import MemberCard from "./MemberCard";
// css
import "./index.scss";

function Members({ data }) {
  return (
    <div className="members">
      <MemberCard
        icon={memberIcon}
        text="Happy Students"
        total={data?.students_count + " +"}
      />
      <Line orientation="horizontal" />
      <MemberCard
        icon={capIcon}
        text="Total Quiz Played"
        total={data?.quiz_played_count}
      />
    </div>
  );
}

export default Members;
