import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "app/baseQuery";

export const discussionForumApi = createApi({
  reducerPath: "discussionForumApi",
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getDiscussion: build.query({
      query: ({ slug, page, tag, institute }) => ({
        url: `/admin/forum-post?slug=${slug}&tag=${tag}&page=${page}&institute=${institute}`,
        method: "GET",
      }),
    }),
    postDiscussion: build.mutation({
      query: ({ slug, selectSortBy, message }) => ({
        url: `/admin/forum-post?slug=${slug}&tag=${selectSortBy}&institute=pocket-exam`,
        method: "POST",
        body: { message: message },
      }),
    }),
  }),
});

export const { useGetDiscussionQuery, usePostDiscussionMutation } =
  discussionForumApi;
export default discussionForumApi;
