import Line from "common/Line";
import React, { useState } from "react";
import ExamDiscussionTable from "../ExamDiscussionTable";
import DiscussionCommentSection from "./DiscussionCommentSection";
import DiscussionDetailHeader from "./DiscussionDetailHeader";
import DiscussionDetailBody from "./DiscussionDetailBody";
import ShareReplyReportSection from "./ShareReplyReportSection";
// css
import "./index.scss";

function ExamDiscussionDetail({ query, data }) {
  const [commentSortBy, setcommentSortBy] = useState(null);
  console.log("comment response is", commentSortBy);
  return (
    <div className="examDiscussionDetail">
      <DiscussionDetailHeader data={data} />
      <Line orientation="horizontal" />
      <div className="examDiscussionDetail__wrapper">
        <DiscussionDetailBody data={data} />
        <ShareReplyReportSection
          postid={data?.data.id}
          setcommentSortBy={setcommentSortBy}
        />
        <Line orientation="horizontal" />
        <DiscussionCommentSection query={query} commentSortBy={commentSortBy} />
      </div>

      <h1>Suggested Topics</h1>
      <ExamDiscussionTable />
    </div>
  );
}

export default ExamDiscussionDetail;
