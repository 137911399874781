import React, { useState } from 'react';
import AllNotifications from './AllNotifications';
import ActivityNotfications from './ActivityNotifications';
import Line from 'common/Line';
// css
import './index.scss';

function Notification() {
  const [tab, setTab] = useState('all');

  return (
    <div className='notification'>
      <h5 className='notification__title'>Notification</h5>
      <div className='notification__toggleBtn'>
        <button
          className={`${tab === 'all' && 'active'}`}
          onClick={() => setTab('all')}
        >
          All
        </button>
        <button
          className={`${tab === 'activity' && 'active'}`}
          onClick={() => setTab('activity')}
        >
          Activity
        </button>
      </div>

      <Line orientation='horizontal' className='notification__line' />

      {tab === 'all' && <AllNotifications />}

      {tab === 'activity' && <ActivityNotfications />}
    </div>
  );
}

export default Notification;
