import ModalBox from 'common/Modal';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useHistory } from 'react-router-dom';
import TimeExpireModal from './TimeExpireModal';
import { usePostEntranceResultMutation } from 'app/services/entranceQuestionApi';
import { CourseExamPageContext } from 'contextAPI/CourseExamPageContext';
// css
import './index.scss';

const Timer = ({ stickyheader }) => {
  const { answers, slug, university, model_slug, questions } = useContext(
    CourseExamPageContext
  );

  const [timeValue, setTimeValue] = useState('');
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(Date.now() + questions?.time * 60 * 1000);

  useEffect(() => {
    localStorage.setItem('time', timeValue);
  }, [timeValue]);

  const [postEntranceResult] = usePostEntranceResultMutation();

  const totalExamRunningTime = localStorage.getItem('time');
  const calculatedtime = questions?.time - totalExamRunningTime / 60 / 1000;

  const history = useHistory();

  const showModal = useCallback(() => {
    // post the current quiz to the server and then show to Modal
    setValue(0);
    setOpen(true);
    let formdata = new FormData();
    formdata.append('answer', JSON.stringify(answers));
    formdata.append('total_time', calculatedtime);
    postEntranceResult({
      formdata,
      slug,
      university,
      model_slug,
    })
      .unwrap()
      .then((res) => setData(res))
      .catch((err) => console.log(err));
  }, [
    answers,
    model_slug,
    postEntranceResult,
    slug,
    university,
    calculatedtime,
  ]);

  const handleClose = useCallback(() => {
    setOpen(false);
    history.push('/quiz/result', {
      data,
      model_slug,
      slug,
      university,
    });
  }, [data, history, model_slug, slug, university]);

  const styled = useCallback(() => ({ width: '40rem', border: 'none' }), []);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, total }) => {
    setTimeValue(total);

    return (
      <div className={`timer ${stickyheader && 'sticky'}`}>
        Time left: {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </div>
    );
  };

  return (
    <>
      <Countdown
        date={value}
        renderer={renderer}
        formatTimeDelta={value}
        onComplete={showModal}
        controlled={false}
      />

      <ModalBox style={styled} open={open} handleClose={handleClose}>
        <TimeExpireModal handleClose={handleClose} />
      </ModalBox>
    </>
  );
};

export default Timer;
