import Button from 'common/Button';
import React from 'react';
import { FcExpired } from 'react-icons/fc';
// css
import './index.scss';

const TimeExpireModal = ({ handleClose }) => {
  return (
    <div className='timeexpiremodal'>
      <FcExpired />
      <h1>Your time is expired.</h1>
      <p>
        Thank you for signing up for Pocket! We hope you have enjoyed the quiz.
      </p>
      <p>*Note: You will be redirected to the result page.</p>
      <Button
        className='timeexpiremodal__btn'
        color='main'
        variant='contained'
        size='md'
        onClick={handleClose}
      >
        Continue
      </Button>
    </div>
  );
};

export default TimeExpireModal;
