import Button from 'common/Button';
import CancelButton from 'common/CancelButton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

const EnrollNowModal = ({ handleClose, data, userPreferenceData }) => {
  const history = useHistory();

  const handleContinue = () => {
    history.push(
      `/exam?slug=${userPreferenceData?.course_slug}&university=${data?.university}&model_slug=${data?.slug}`
    );
  };

  return (
    <div className='enrollNowModal'>
      <header className='enrollNowModal__header'>
        <h3>{data.name}</h3>
        <CancelButton onClick={handleClose} />
      </header>

      <div className='enrollNowModal__body'>
        <div className='enrollNowModal__body--flex'>
          <p>Total number of Questions</p>
          <strong>{data.total_question}</strong>
        </div>
        <div className='enrollNowModal__body--flex'>
          <p>Total Exam time</p>
          <strong>{data.time} minutes</strong>
        </div>
        <div className='enrollNowModal__body--flex'>
          <p>Marks per correct Question</p>
          <strong>5</strong>
        </div>
        <div className='enrollNowModal__body--flex'>
          <p>Marks per Wrong</p>
          <strong>0</strong>
        </div>
      </div>

      <div className='enrollNowModal__btn'>
        <Button
          className='enrollNowModal__btn--btn'
          size='md'
          variant='contained'
          color='main'
          onClick={handleContinue}
        >
          continue exam
        </Button>
      </div>
    </div>
  );
};

export default EnrollNowModal;
