import React, { useContext } from 'react';
import { FeedbackContext } from '../index';
import { dateConverter } from 'utils/dateconverter';
// css
import './index.scss';

const SubmitFeedbackHeader = () => {
  const { data } = useContext(FeedbackContext);

  const completed_on = dateConverter(data?.completed_on, 'LLLL');

  return (
    <header className='submitfeedbackheader'>
      <h5>{data?.title}</h5>
      <p>Completed on {completed_on}</p>
    </header>
  );
};

export default SubmitFeedbackHeader;
