import React from "react";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import "./index.scss";

function ModalBox({ children, open, style, handleClose }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modalbox"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={style} className="modalbox__body">
          {children}
        </div>
      </Fade>
    </Modal>
  );
}

export default ModalBox;
