import React from "react";
import QuizTime from "assets/images/Quiz_time.svg";
import HeaderContainer from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/HeaderContainer";
import Line from "common/Line";
import Button from "common/Button";
// css
import "./index.scss";

function AttemptExam() {
  return (
    <div className="attemptExam">
      <HeaderContainer
        title="Attempt your first Exam"
        subtitle="You are almost there. Resume your Exam."
      />

      <div className="attemptExam__quizTime">
        <img src={QuizTime} alt="QuizTimeImage" />
        <p>Medican Surgical Nursing Exam</p>
        <div className="attemptExam__quizTime--minusQuestions">
          <p>30 mins</p>
          <Line orientation="vertical" />
          <p>100 Questions</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="attemptExam__quizTime--btn"
            color="main"
            size="md"
            variant="outlined"
          >
            Resume Exam
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AttemptExam;
