import React, { useCallback, useState } from "react";
import ReplyIcon from "assets/icons/arrow/replyIcon.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./index.scss";
import Button from "common/Button";
import CancelButton from "common/CancelButton";
import { usePostCommentMutation } from "app/services/discussionCommentApi";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";

const ReplyFormModal = ({ handleClose, postid, setcommentSortBy }) => {
  const [value, setValue] = useState("");
  const [postComment, { isLoading }] = usePostCommentMutation({});
  const { data } = useSelector(userPreferenceSelector);

  let slug = data.course_slug;
  const handleSubmit = useCallback(() => {
    postComment({ slug, value, postid })
      .unwrap()
      .then((res) => {
        handleClose();
        setcommentSortBy(res);
        // ExamDiscussionTable();
        // console.log("fafa");
        return res;
      })
      .catch((err) => console.log(err));
  }, [handleClose, postComment, postid, setcommentSortBy, slug, value]);
  return (
    <div className="replyFormModal">
      <header className="replyFormModal__header">
        <div className="replyFormModal__header--flex">
          <img src={ReplyIcon} alt="reply-icon" />
          <h3>How do I know this is helpful to the students?</h3>
        </div>
        <CancelButton onClick={handleClose} />
      </header>
      <div className="replyFormModal__editor">
        <CKEditor
          config={{ placeholder: "Type here." }}
          editor={ClassicEditor}
          data={value}
          onChange={(event, editor) => {
            const data = editor.getData();
            setValue(data);
          }}
        />
        <div className="replyFormModal__btnGroups">
          <Button
            className="replyFormModal__btnGroups--cancelBtn"
            size="md"
            variant="outlined"
            color="sub"
            onClick={handleClose}
          >
            cancel
          </Button>
          <Button
            className="replyFormModal__btnGroups--replyBtn"
            size="md"
            variant="contained"
            color="main"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            reply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReplyFormModal;
