import Line from 'common/Line';
import { CourseExamPageContext } from 'contextAPI/CourseExamPageContext';
import React, { useContext } from 'react';
// css
import './index.scss';

const QuestionsLeft = () => {
  const { totalAnswered, total_questions, calculateTotalNotAnswered } =
    useContext(CourseExamPageContext);

  return (
    <div className='questionsLeft'>
      <h4>Question Left</h4>
      <Line orientation='horizontal' />
      <div className='questionsLeft__flex'>
        <div className='questionsLeft__flex--answered'>
          <span>{totalAnswered}</span>
          <p>Answered</p>
        </div>
        <div className='questionsLeft__flex--notanswered'>
          <span>{calculateTotalNotAnswered()}</span>
          <p>Unanswered</p>
        </div>
        <div className='questionsLeft__flex--notvisited'>
          <span>{total_questions}</span>
          <p>Questions</p>
        </div>
      </div>
    </div>
  );
};

export default QuestionsLeft;
