import axios from "axios";
const token = localStorage.getItem("token");

export const URL = "https://server.growthlab.com.np";
export const baseURL = "https://server.growthlab.com.np/api";

// Set config defaults when creating the instance
export const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    Authorization: token ? `Bearer ${token}` : null,
    "Access-Control-Allow-Origin": '"*"',
  },
});
export default URL;
