import { usePostEntranceResultMutation } from 'app/services/entranceQuestionApi';
import Button from 'common/Button';
import CancelButton from 'common/CancelButton';
import { CourseExamPageContext } from 'contextAPI/CourseExamPageContext';
import React, { memo, useCallback, useContext } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
// css
import './index.scss';

const SubmitModal = ({ handleClose }) => {
  const { answers, slug, university, model_slug, questions } = useContext(
    CourseExamPageContext
  );
  const history = useHistory();

  const [postEntranceResult, { isLoading }] = usePostEntranceResultMutation({
    fixedCacheKey: 'entrance-result',
  });

  const time = localStorage.getItem('time');
  // time is in ms convert to min
  const user_submit_time = time / 1000 / 60;
  // exam time is in min so convert to ms
  const exam_time = questions?.time;
  // total exam_time - users exam taken time
  const calculatetime = exam_time - user_submit_time;

  const handleSubmit = useCallback(() => {
    let formdata = new FormData();
    formdata.append('answer', JSON.stringify(answers));
    formdata.append('total_time', calculatetime);
    postEntranceResult({ formdata, slug, university, model_slug })
      .unwrap()
      .then((res) =>
        history.push('/quiz/result', {
          data: res,
          model_slug,
          slug,
          university,
        })
      )
      .catch((err) => console.log(err));
  }, [
    answers,
    postEntranceResult,
    slug,
    university,
    model_slug,
    history,
    calculatetime,
  ]);

  return (
    <div className='submitModal'>
      <div className='submitModal__cancelbtn'>
        <CancelButton onClick={handleClose} />
      </div>

      <div className='submitModal__body'>
        <div className='submitModal__body--icon'>
          <FiAlertTriangle />
        </div>
        <h1>Are you sure you want to submit the test?</h1>
        <p>Once submitted test can’t be reattempted.</p>
        <div className='submitModal__body--btnGroups'>
          <Button
            className='submitModal__body--btnGroups-btn'
            variant='contained'
            size='md'
            color='main'
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            yes
          </Button>
          <Button
            className='submitModal__body--btnGroups-btn'
            variant='outlined'
            size='md'
            color='sub'
            onClick={handleClose}
          >
            no
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(SubmitModal);
