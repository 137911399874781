import React, { createContext } from 'react';
import ResultSection from './ResultSection';
import FeedbackSection from './FeedbackSection';
import Container from 'common/Container';
import SubmitFeedbackHeader from './SubmitFeedbackHeader';
import { useLocation } from 'react-router-dom';
import useScrollToTop from 'hooks/useScrollToTop';
import Buttons from './Buttons';
// css
import './index.scss';

export const FeedbackContext = createContext(null);

function SubmitFeedbackPage() {
  useScrollToTop();
  const { state } = useLocation();
  // console.log('state', state);
  const { model_slug, data, university, slug } = state;

  return (
    <FeedbackContext.Provider value={{ data, model_slug, university, slug }}>
      <Container>
        <div className='submitFeedbackPage'>
          <SubmitFeedbackHeader />

          <div className='submitFeedbackPage__body'>
            <ResultSection />
            <Buttons />
            <FeedbackSection />
          </div>
        </div>
      </Container>
    </FeedbackContext.Provider>
  );
}

export default SubmitFeedbackPage;
