import React from "react";
import RecentQuizeCard from "./RecentQuizeCard";
import HeaderContainer from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/HeaderContainer";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useSelector } from "react-redux";
import { useGetRecentQuizQuery } from "app/services/recentQuizApi";
// css
import "./index.scss";
import { useGetQuizDetailsQuery } from "app/services/quizDetailsApi";

function RecientQuizes() {
  const { data: userPreference } = useSelector(userPreferenceSelector);

  const { data, isFetching } = useGetRecentQuizQuery(
    {
      slug: userPreference?.course_slug,
    },
    { refetchOnMountOrArgChange: 60 }
  );

  return isFetching ? (
    <div />
  ) : (
    <div className={`RecientQuizes ${isFetching ? "disabled" : " "}`}>
      <HeaderContainer
        title={data?.message}
        subtitle={data?.subtitle}
        arrow="true"
        buttonText="all quizes"
      />
      <div className="RecientQuizes__body">
        <RecentQuizeCard
          data={data?.testSeries}
          userPreference={userPreference}
        />
      </div>
    </div>
  );
}

export default RecientQuizes;
