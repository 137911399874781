import React from 'react';
import TextArea from 'common/TextArea';
import Button from 'common/Button';
import Ratings from 'common/Ratings';
// css
import './index.scss';

function FeedbackSection() {
  return (
    <section className='feedbackSection'>
      <h4>Rate this Exam Question</h4>
      <p>Please Share you feedback.</p>

      <Ratings />

      <h5>What did you like the best?</h5>

      <div className='feedbackSection__selectFeedback'>
        <button>Top Relevance</button>
        <button>User Experience</button>
        <button>Other</button>
      </div>

      <TextArea
        className='feedbackSection__textarea'
        placeholder='Please add your Suggestions...'
        size='md'
      />

      <div className='feedbackSection__btnGroups'>
        <Button
          className='feedbackSection__btnGroups--submit'
          color='main'
          variant='contained'
          size='md'
        >
          submit feedback
        </Button>
        <Button color='sub' variant='outlined' size='md'>
          exit exam
        </Button>
      </div>
    </section>
  );
}

export default FeedbackSection;
