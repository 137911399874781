import {
  AiOutlineDashboard,
  AiOutlineFileSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { ImBooks } from "react-icons/im";
import { MdStars } from "react-icons/md";
import { GiDiscussion } from "react-icons/gi";
import { BsListCheck } from "react-icons/bs";

export const sidebarData = [
  {
    id: 0,
    exact: true,
    name: "dashboard",
    icon: <AiOutlineDashboard />,
    link: "/pocket-exam/dashboard",
  },
  // {
  //   id: 1,
  //   name: 'my saved exam',
  //   icon: <MdStars />,
  //   link: '/pocket-exam/my-saved-exam',
  // },
  {
    id: 1,
    name: "QBank",
    icon: <ImBooks />,
    link: "/pocket-exam/questions-bank",
  },
  // {
  //   id: 3,
  //   name: 'past exam',
  //   icon: <BsListCheck />,
  //   link: '/pocket-exam/past-exam',
  // },
  // {
  //   id: 4,
  //   name: 'mock test',
  //   icon: <AiOutlineFileSearch />,
  //   link: '/pocket-exam/mock-test',
  // },
  {
    id: 2,
    name: "exam discussion",
    icon: <GiDiscussion />,
    link: "/pocket-exam/exam-discussion",
  },
  // {
  //   id: 6,
  //   name: 'my purchases',
  //   icon: <AiOutlineShoppingCart />,
  //   link: '/pocket-exam/my-purchases',
  // },
];
