import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/baseQuery';

export const userPreferenceApi = createApi({
  reducerPath: 'userPreferenceApi',
  baseQuery: baseQuery,
  tagTypes: ['Preference'],
  endpoints: (build) => ({
    getUserPreference: build.query({
      query: () => ({
        url: `/admin/web-user-preference?institute=pocket-exam`,
        method: 'GET',
      }),
      providesTags: ['Preference'],
    }),
    updateUserPreference: build.mutation({
      query: (body) => ({
        url: '/admin/web-user-preference',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Preference'],
    }),
  }),
});

export const { useGetUserPreferenceQuery, useUpdateUserPreferenceMutation } =
  userPreferenceApi;
