import React, { useCallback, useContext, useEffect, useState } from "react";
import QuestionQuizCard from "../QuestionQuizCard";
import { CourseExamPageContext } from "contextAPI/CourseExamPageContext";
import useScrollToTop from "hooks/useScrollToTop";
import Pagination from "common/Pagination";
import MathJax from "mathjax3-react";
// css
import "./index.scss";

const QuestionsListWithPagination = () => {
  const { questions, setSubjectName, subjectName } = useContext(
    CourseExamPageContext
  );
  const items = questions?.entrancequestion;

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    questions?.questionstructure[0]?.count
  );
  useScrollToTop(itemOffset);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(questions?.questionstructure.length);
  }, [itemOffset, items, itemsPerPage, questions?.questionstructure.length]);

  const handlePageClick = useCallback(
    (event) => {
      const newOffset = (event.selected * itemsPerPage) % items?.length;
      setItemOffset(newOffset);
    },
    [items, itemsPerPage]
  );

  useEffect(() => {
    if (currentItems) {
      setSubjectName(currentItems[0]?.subject);
    }
  }, [currentItems, setSubjectName]);

  const findSameSubjectNameAsCurrentQuestionsSubject =
    questions?.questionstructure?.find(
      (count) => count.subject === subjectName
    );

  useEffect(() => {
    if (findSameSubjectNameAsCurrentQuestionsSubject) {
      setItemsPerPage(findSameSubjectNameAsCurrentQuestionsSubject?.count);
    }
  }, [findSameSubjectNameAsCurrentQuestionsSubject]);

  return (
    <MathJax.Provider
      url="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js"
      options={{
        tex: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
        },
      }}
    >
      {currentItems?.map((question) => (
        <QuestionQuizCard key={question.id} data={question} />
      ))}
      <Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
    </MathJax.Provider>
  );
};

export default React.memo(QuestionsListWithPagination);
