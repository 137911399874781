import React, { useState } from "react";
import LeaderboardTable from "./LeaderboardTable";
import HeaderContainer from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/HeaderContainer";
import Button from "common/Button";
import { IoIosArrowForward } from "react-icons/io";
import ModalBox from "common/Modal";
import LeaderboardModal from "./LeaderboardModal/LeaderboardModal";
// css
import "./index.scss";
import { useGetLeaderboardQuery } from "app/services/leaderboardApi";
import useQuery from "hooks/useQuery";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useSelector } from "react-redux";
function Leaderboard() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const { data: userPreference } = useSelector(userPreferenceSelector);

  const { data, isFetching } = useGetLeaderboardQuery(
    {
      slug: userPreference?.course_slug,
      time: "All",
      page: 1,
      institute: "pocket-exam",
      testname: "",
    },
    { refetchOnMountOrArgChange: 60 }
  );

  return (
    <>
      <div className="leaderboard">
        <div className="leaderboard__container">
          <HeaderContainer title="Leaderboard" />

          <div className="leaderboard__body">
            <LeaderboardTable dat={data} />
          </div>
        </div>

        <Button
          size="xl"
          className="leaderboard__btn"
          onClick={() => setOpen(true)}
        >
          see all <IoIosArrowForward />
        </Button>
      </div>
      <ModalBox style={{ width: "57rem" }} open={open} setOpen={setOpen}>
        <LeaderboardModal dat={data} handleClose={handleClose} />
      </ModalBox>
    </>
  );
}

export default Leaderboard;
