import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/baseQuery';

export const questionSetApi = createApi({
  reducerPath: 'questionSetApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getQuestionSets: build.query({
      query: ({ slug, university }) => ({
        url: `/admin/test-series-info?slug=${slug}&university=${university}&institute=pocket-exam`,
        method: 'GET',
      }),
    }),
    getPastQuestionsSets: build.query({
      query: ({ slug, university }) => ({
        url: `/my/pastexam?slug=${slug}&university=${university}&institute=pocket-exam`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetQuestionSetsQuery, useGetPastQuestionsSetsQuery } =
  questionSetApi;
