import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance as api } from "app/api";

export const loginUser = createAsyncThunk(
  "users/login",
  async (user, thunkAPI) => {
    try {
      const res = await api.post("/student/login/", user);
      const data = await res.data;
      if (data.status_code === 202) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user_info", JSON.stringify(data.user_info));
        return { data, token: data.access_token };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const token = window.localStorage.getItem("token");
const user_info = JSON.parse(window.localStorage.getItem("user_info"));

const userLoginSlice = createSlice({
  name: "userLogin",
  initialState: {
    data: token ? user_info : [],
    token: token,
    isAuthenticated: token ? true : false,
    isFetching: false,
    errorMessage: {},
    error: false,
  },
  reducers: {
    setAuth: (state, { payload }) => {
      state.data = payload;
    },
    resetLogin: (state) => {
      state.data = [];
      state.isFetching = false;
      state.errorMessage = null;
      state.error = false;
    },
    logoutUser: (state, _) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user_info");
      state.data = [];
      state.isAuthenticated = false;
      state.isFetching = false;
      state.errorMessage = null;
      state.error = false;
      window.location.reload(true);
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.token = payload.token;
      state.isAuthenticated = true;
      state.isFetching = false;
      state.errorMessage = {};
      state.error = false;
      window.location.reload(true);
    },
    [loginUser.pending]: (state, _) => {
      state.isFetching = true;
      state.errorMessage = {};
      state.error = false;
    },
    // errorMessage handling
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isAuthenticated = false;
      state.errorMessage = payload;
      state.error = true;
    },
  },
});

export const { resetLogin, logoutUser, setAuth } = userLoginSlice.actions;

export const loginSelector = (state) => state.auth;

export default userLoginSlice.reducer;
