import React, { useContext, useEffect } from 'react';
import CrossIcon from 'assets/icons/Header/cross.svg';
import Line from 'common/Line';
import UserProfile from './UserProfile';
import { sidebarData } from 'pages/PocketExamPage/Sidebar/sidebar.data';
import { Link, useLocation } from 'react-router-dom';
import { HeaderContext } from '../index';
// css
import './index.scss';

const HeaderSidebar = () => {
  const { open, handleClose } = useContext(HeaderContext);

  const location = useLocation();
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
  }, [open]);
  return (
    <div
      onClick={handleClose}
      className={`headersidebar ${open ? 'active' : ''}`}
    >
      <div
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
        className={`headersidebar__content ${open ? 'active' : ''} `}
      >
        <div className='headersidebar__body'>
          <UserProfile handleClose={handleClose} />
          <Line orientation='horizontal' />

          <div className='headersidebar__body--linkItems'>
            {sidebarData.map((sidebar) => (
              <Link key={sidebar.id} to={sidebar.link} onClick={handleClose}>
                <div
                  className={`headersidebar__body--linkItems-item ${
                    sidebar.link === location.pathname && 'active'
                  }`}
                >
                  {sidebar.icon}
                  {sidebar.name}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div onClick={handleClose} className='headersidebar__cancelIcon'>
          <img src={CrossIcon} alt='cancel-icon' />
        </div>
      </div>
    </div>
  );
};

export default HeaderSidebar;
