import React from 'react';
import BenefitsCards from './BenefitsCard/BenefitsCard';

import './index.css';

const BenefitsForOurLearners = () => {
  return (
    <div className='benefitsForOurLearners'>
      <p>LEARN AT YOUR PACE</p>
      <h4>Enjoyable Benefits for Our Learners.</h4>

      <BenefitsCards />
    </div>
  );
};

export default BenefitsForOurLearners;
