import Container from "common/Container";
import React from "react";

import SubHeader from "../SubHeader/SubHeader";
import { Wrapper } from "./TermsOfUseElements";

const TermsOfUse = () => {
  return (
    <>
      <SubHeader title="Terms of Use" date="january 15,2020" />
      <Container>
        <Wrapper>
          {/* About us and these terms */}
          <div className="policy__title">About us and these terms</div>

          <ol type="1">
            <li className="policy__list1">
              <dd>
                Hi, we’re Pocket and welcome to Pocket Exam, a subscription
                service for digital Items created by designers and creatives
                from around the world. When we say ‘we’, ‘us’ or ‘Pocket’ it’s
                because that’s who we are and we own and run the Pocket Exam
                platform.
              </dd>
            </li>
            <li className="policy__list2">
              <dd>
                When you create an Pocket Exam account and accept these terms
                you become a member of our community. During your time with us
                you agree to follow the ground rules outlined in these terms so
                please read and understand them. If you don’t accept the terms
                then we’ll be sad, but you will need to leave because your
                presence on and use of the Pocket Exam platform is conditional
                on your acceptance to be bound by these terms whether you become
                a member or not.
              </dd>
            </li>
            <li className="policy__list3">
              <dd>
                The Items on Pocket Exam are owned by the respective
                rightsholder. They are licensed to you under the terms of the{" "}
                <span>Pocket Exam License (as amended from time to time)</span>{" "}
                at the direction of authors.
              </dd>
            </li>
          </ol>

          {/* Becoming a Subscriber */}
          <div className="policy__title">Becoming a Subscriber</div>
          <ol>
            <li value="4" className="policy__list1">
              <dd>
                <span>Subscription types:</span> When you become a Subscriber
                you get to access our library of Items that must be used in
                accordance with the Pocket Exam License. Elements for
                Individuals is a single seat usage subscription only, available
                for use by a single real person or for a single seat usage by a
                company with less than fifty employees. You are not permitted to
                share the seat. Pockets for Teams is a multiple seat
                subscription, available to a group of people or a company with
                less than fifty employees.
              </dd>
            </li>
            <li className="policy__list2">
              <dd>
                <span>Authority to agree to these terms:</span> If you are a
                company or organization, both you and the real person who signs
                up on your behalf represent and warrant that this person has the
                authority to bind your company or organization.
              </dd>
            </li>
            <li className="policy__list3">
              <dd>
                <span>Your responsibility:</span> You promise that information
                you give us is true, accurate and complete and that you will
                keep your account information up-to-date (including a current
                email address). Your subscription is not transferable. You are
                responsible for any use of Pocket Exam that occurs in
                conjunction with your username and password so keep your
                password secure and don’t let any other person use your username
                or password. If you realise there’s any unauthorized use of your
                password or any breach of security you need to let us know
                immediately.
              </dd>
            </li>
          </ol>

          {/* Transacting as a Subscriber */}
          <div className="policy__title">Transacting as a Subscriber:</div>
          <ol>
            <li value="7" className="policy__list1">
              <dd>
                You can pay for your subscription via Khalti, IME Pay, eSEWA,
                PayPal or credit card. You may be required to pay a handling fee
                depending on the method of payment you choose.
              </dd>
            </li>
            <li className="policy__list2">
              <dd>
                By signing on as a Subscriber you must agree to the individual
                payment terms that relate to your chosen method of payment, as
                notified to you. You agree that at the conclusion of a
                time-limited free trial or introductory offer, your subscription
                will automatically renew at the pricing and frequency notified
                to you at the start of the trial or offer.
              </dd>
            </li>
            <li className="policy__list3">
              <dd>
                In the event that you select an ongoing, regular payment method
                (for example a monthly credit card or PayPal payment) and that
                payment fails to occur, we may immediately suspend your
                subscription until payment has been made. If we suspend your
                subscription, your rights under these terms and the{" "}
                <span>Pocket Exam License</span> will also be suspended.
              </dd>
            </li>

            <div className="policy__subscriberNotice">
              <p>
                This means that you may incur additional costs when subscribing
                to Pocket Exam, which we have no control over.
              </p>
            </div>
          </ol>

          {/* Taxes*/}
          <div className="policy__title">Taxes:</div>
          <ol type="a">
            <li className="policy__list1">
              <dd>
                The final prices and fees are inclusive of transactional taxes
                where relevant (like VAT and GST). Our promotional materials
                will state whether or not the price is inclusive or exclusive of
                transactional taxes.
              </dd>
            </li>
            <li className="policy__list2">
              <dd>
                You are responsible for paying all other fees and taxes
                associated with your use of Pocket Exam wherever levied. Your
                responsibility includes withholding tax if it applies, unless we
                already process that withholding tax. We may collect
                geographical location information to determine your location,
                which may be used for tax purposes (so location information you
                give us must be accurate for tax residency purposes).
              </dd>
            </li>
          </ol>

          {/* Definitions */}
          <div className="policy__titleDefinitions">Definitions:</div>

          <div className="policy__definitionsSubTitleTab">
            <span className="policy__definitionsTitle">Pocket:</span>
            <span className="policy__definitionsContent">
              Growth Lab Technology and Innovations Pvt. Ltd
            </span>
          </div>

          <div className="policy__definitionsSubTitleTab">
            <span className="policy__definitionsTitle">Pocket Content:</span>
            <span className="policy__definitionsContent">
              is all the content that we have put on Pocket Exam and includes
              the design, compilation, and look and feel of Pocket Exam, and
              copyright, trademarks, designs and other intellectual property on
              Pocket Exam (unless otherwise stated and excluding content owned
              by others like author content)
            </span>
          </div>

          <div>
            <span className="policy__definitionsTitle">Subscriber:</span>
            <span className="policy__definitionsContent">
              a person who signs up by creating an account and accepting these
              terms.
            </span>
          </div>

          <div className="policy__definitionsSubTitleTab">
            <span className="policy__definitionsTitle">Payment method:</span>
            <span className="policy__definitionsContent">
              any payment agent or method we make available on Pocket Exam.
            </span>
          </div>
        </Wrapper>
      </Container>
    </>
  );
};

export default TermsOfUse;
