import Button from 'common/Button';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FeedbackContext } from '../index';

const Buttons = () => {
  const { university, model_slug, slug, data } = useContext(FeedbackContext);
  const history = useHistory();

  const handleClickReview = useCallback(
    () =>
      history.push(
        `/quiz/review/slug=${slug}&university=${university}&model_slug=${model_slug}`,
        { data, slug }
      ),
    [data, history, model_slug, slug, university]
  );

  const handleClickRetakeExam = useCallback(
    () =>
      history.push(
        `/exam?slug=${slug}&university=${university}&model_slug=${model_slug}`
      ),
    [slug, university, history, model_slug]
  );

  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '3rem 0 8rem 0',
        gap: '2rem',
      }}
    >
      <Button
        color='main'
        variant='outlined'
        size='md'
        onClick={handleClickReview}
      >
        review answers
      </Button>
      <Button
        color='main'
        variant='outlined'
        size='md'
        onClick={handleClickRetakeExam}
      >
        Retake Exam
      </Button>
    </section>
  );
};

export default Buttons;
