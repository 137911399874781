import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import GoogleLoginImg from "assets/images/sociallogin/GoogleLogin.svg";
// import firebase from "src/app/services/firebase";
// css
import "./index.scss";
import { loginUser, signupUser, userSelector } from "../Register/userSlice";
import firebase from "app/services/firebase";
const SocialAuth = () => {
  // redux
  const dispatch = useDispatch();
  const { userRegister } = useSelector(userSelector);

  useEffect(() => {
    // checks if user is registered or not and if status===201 thens proceeds login
    if (userRegister?.status === 201) {
      dispatch(
        loginUser({
          email: userRegister.email,
          password: "RU2tMx$K",
          type: "google",
          device_id: "",
        })
      );
    }
  }, [dispatch, userRegister]);

  // firebase login with google
  const loginWithGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((user) => {
        if (user) {
          dispatch(
            signupUser({
              email: user.additionalUserInfo.profile.email,
              password: "asdsadasdasdsad",
              profile: {
                first_name: user.additionalUserInfo.profile.given_name,
                last_name: user.additionalUserInfo.profile.family_name,
                phone: "",
                image_uri: "",
                device_identifier: uuidv4(),
                login_type: "google",
              },
            })
          );
        }
      });
  };

  const handleGoogleAuth = () => {
    loginWithGoogle();
  };
  return (
    <div className="socialLogin">
      <div className="socialLogin__buttons">
        <h3>Login with social networks</h3>
        {/* <img
          onClick={handleFacebookAuth}
          src={FacebookLogin}
          alt='Facebook Login Button'
        /> */}
        <img
          onClick={handleGoogleAuth}
          src={GoogleLoginImg}
          alt="Google Login Button"
        />
      </div>
      {/* <hr className='social__divider' />
      <hr className='social__line' /> */}
    </div>
  );
};

export default SocialAuth;
