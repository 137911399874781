import moment from 'moment';

export const msToTime = (duration) => {
  // var milliseconds = Math.floor((duration % 1000) / 100),
  var seconds = Math.floor((duration / 1000) % 60);
  var minutes = Math.floor((duration / (1000 * 60)) % 60);
  var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours + ':' + minutes + ':' + seconds;
};

export const minToHr = (mins) => {
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError(
      'Valid input should be greater than or equal to 0 and less than 1440.'
    );
  }
  var hour = (mins / 60) | 0,
    min = mins % 60 | 0;
  return moment.utc().hours(hour).minutes(min).format('hh:mm');
};

// export const msToMin = (duration) => {
//   var minutes = Math.floor((duration / (1000 * 60)) % 60);

//   minutes = minutes < 10 ? '0' + minutes : minutes;

//   return minutes;
// };

// export const minToTime = (duration) => {
//   var seconds = Math.floor(duration % 60);
//   var minutes = Math.floor((duration / 60) % 60);
//   var hours = Math.floor((duration / (60 * 60)) % 24);

//   hours = hours < 10 ? '0' + hours : hours;
//   minutes = minutes < 10 ? '0' + minutes : minutes;
//   seconds = seconds < 10 ? '0' + seconds : seconds;

//   return hours + ':' + minutes + ':' + seconds;
// };
