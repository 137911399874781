import Container from "common/Container";
import React, { useContext } from "react";
import SectionContainer from "../SectionContainer";
import CarouselSlider from "common/Carousel";
import CourseDetailsTitle from "../CourseDetailsTitle";
import LeaderboardCard from "./LeaderboardCard";
import { useGetLeaderboardQuery } from "app/services/leaderboardApi";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useSelector } from "react-redux";
import { CourseDetailsContext } from "../index";
const LeaderboardSection = ({ data }) => {
  // const leaderboard = data?.progress?.leaderboard;
  const { data: userPreference } = useSelector(userPreferenceSelector);

  // const { data, isFetching } = useGetLeaderboardQuery(
  //   {
  //     slug: userPreference?.course_slug,
  //     time: "All",
  //     page: 1,
  //     institute: "pocket-exam",
  //     testname: value?.data.name,
  //   },
  //   { refetchOnMountOrArgChange: 60 }
  // );
  return (
    <Container>
      <SectionContainer>
        <CourseDetailsTitle title="Leaderboard 🏆" />
        <div style={{ marginTop: "4rem" }}>
          <CarouselSlider itemsToShow={4}>
            {data?.progress.leaderboard?.map((data, index) => (
              <LeaderboardCard key={index} data={data} />
            ))}
          </CarouselSlider>
        </div>
      </SectionContainer>
    </Container>
  );
};

export default LeaderboardSection;
