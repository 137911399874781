import Dashboard from "pages/PocketExamPage/MenuRoutesComponent/Dashboard/Dashboard";
import ExamDiscussion from "./MenuRoutesComponent/ExamDiscussion";
import MockTest from "./MenuRoutesComponent/MockTest";
import MyPurchases from "./MenuRoutesComponent/MyPurchases";
import MySaveExam from "./MenuRoutesComponent/MySaveExam";
import PastExam from "./MenuRoutesComponent/PastExam/PastExam";
import QBank from "./MenuRoutesComponent/QBank";

export const routes = [
  {
    path: "/pocket-exam/dashboard",
    exact: true,
    content: () => <Dashboard />,
  },
  // {
  //   path: "/pocket-exam/my-saved-exam",
  //   content: () => <MySaveExam />,
  // },
  {
    path: "/pocket-exam/questions-bank",
    content: () => <QBank />,
  },
  // {
  //   path: "/pocket-exam/past-exam",
  //   content: () => <PastExam />,
  // },
  // {
  //   path: "/pocket-exam/mock-test",
  //   content: () => <MockTest />,
  // },
  {
    path: "/pocket-exam/exam-discussion",
    exact: true,
    content: () => <ExamDiscussion />,
  },
  // {
  //   path: "/pocket-exam/my-purchases",
  //   content: () => <MyPurchases />,
  // },
];
