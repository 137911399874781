import Button from 'common/Button';
import Container from 'common/Container';
import ModalBox from 'common/Modal';
import React, { useCallback, useContext, useState } from 'react';
import SubmitModal from './SubmitModal';
import { useHistory } from 'react-router-dom';
// css
import './index.scss';
import { CourseExamPageContext } from 'contextAPI/CourseExamPageContext';

const CourseExamFooter = () => {
  const { answers } = useContext(CourseExamPageContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const styles = useCallback(() => ({ width: '47.4rem', border: 'none' }), []);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className='courseExamFooter'>
      <Container>
        <div className='courseExamFooter__content'>
          <Button
            onClick={handleGoBack}
            variant='outlined'
            size='md'
            color='sub'
          >
            back
          </Button>
          <Button
            onClick={handleOpen}
            variant='contained'
            size='md'
            color='main'
            disabled={answers.length === 0}
          >
            SAVE & NEXT
          </Button>
        </div>
      </Container>
      <ModalBox style={styles} open={open} handleClose={handleClose}>
        <SubmitModal handleClose={handleClose} />
      </ModalBox>
    </div>
  );
};

export default CourseExamFooter;
