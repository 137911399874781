import React, { useContext } from 'react';
import timeIcon from 'assets/icons/submitFeedback/time.svg';
import answerIcon from 'assets/icons/submitFeedback/answer.svg';
import questionIcon from 'assets/icons/submitFeedback/question.svg';
import notAnswerIcon from 'assets/icons/submitFeedback/notanswer.svg';
import accuracyIcon from 'assets/icons/submitFeedback/accuracy.svg';
import ResultItem from './ResultItem';
import Line from 'common/Line';
import { FeedbackContext } from '../index';
import notVisitedIcon from 'assets/icons/submitFeedback/notvisited.svg';
import { msToTime } from 'utils/timeConverter';
// css
import './index.scss';

function ResultSection() {
  const { data } = useContext(FeedbackContext);

  return (
    <section className='resultSection'>
      <div className='resultSection__flex'>
        <ResultItem
          icon={timeIcon}
          name='time'
          value={msToTime(data?.result?.finish_time * 1000 * 60)}
        />
        <ResultItem
          icon={answerIcon}
          name='correct'
          value={data?.result?.correct}
        />
      </div>

      <Line orientation='horizontal' />

      <div className='resultSection__flex'>
        <ResultItem
          icon={questionIcon}
          name='questions'
          value={data?.total_question}
        />
        <ResultItem
          icon={notAnswerIcon}
          name='incorrect'
          value={data?.result?.incorrect}
        />
      </div>

      <Line orientation='horizontal' />

      <div className='resultSection__flex'>
        <ResultItem
          icon={accuracyIcon}
          name='accuracy'
          value={`${data?.result?.accuracy.toFixed(2)}%`}
        />
        <ResultItem
          icon={notVisitedIcon}
          name='not answered'
          value={data?.result?.unattendent}
        />
      </div>
    </section>
  );
}

export default ResultSection;
