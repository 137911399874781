import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { instance as api } from "app/api";

export const signupUser = createAsyncThunk(
  "user/signupUser",
  async ({ email, password, profile }, { rejectWithValue }) => {
    try {
      const params = {
        email,
        password,
        profile,
      };

      const response = await api.post("/student/register/", params);

      const { data } = response;
      return { ...data, email: email };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const loginUser = createAsyncThunk(
  "users/login",
  async (user, thunkAPI) => {
    try {
      const res = await api.post("/student/login/", user);
      const data = await res.data;
      if (data.status_code === 202) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user_info", JSON.stringify(data.user_info));
        return {
          data,
          token: data.access_token,
          status_code: data.status_code,
        };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      // console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resendEmailVerification = createAsyncThunk(
  "user/resendEmailVerification",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await api.post("/resend_email_confirmation/", {
        email,
      });
      const { data } = response;

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgetPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await api.post("/reset/", {
        email,
      });
      const { data } = response;

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const changeNewPassword = createAsyncThunk(
  "user/changeNewPassword",
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await api.put("/reset/", {
        token,
        password,
      });
      const { data } = response;

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "register",
  initialState: {
    auth: "",
    userData: [],
    userRegister: {},
    forgotPassword: {},
    changeNewPassword: {},
    resendEmail: {},
    status: "",
    isFetching: false,
    isError: false,
    isSuccess: false,
    errorMessage: null,
  },
  reducers: {
    errorReset: (state, _) => {
      state.isError = false;
      state.errorMessage = null;
      state.status = "";
    },
    saveAuth: (state, { payload }) => {
      state.auth = payload;
    },
    logoutUser: (state, action) => {
      localStorage.removeItem("token");
      localStorage.removeItem("fullname");
      localStorage.removeItem("email");
      localStorage.removeItem("image_uri");
      state.userData = null;
      window.location.reload(true);
    },
    resetRegisterUser: (state, action) => {
      state.userRegister = null;
      state.isFetching = false;
      state.isSuccess = false;
    },
  },
  extraReducers: {
    // Sign Up
    [signupUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.userRegister = payload;
    },
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    // verify
    [resendEmailVerification.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.resendEmail = payload;
    },
    [resendEmailVerification.pending]: (state) => {
      state.isFetching = true;
    },
    [resendEmailVerification.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.resendEmail = payload.message;
    },

    // Forgot Password
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.forgotPassword = payload;
      state.errorMessage = "";
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      //
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    // Change New Password
    [changeNewPassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.changeNewPassword = payload;
      state.errorMessage = "";
    },
    [changeNewPassword.pending]: (state, { payload }) => {
      state.isFetching = true;
    },
    [changeNewPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const { logoutUser, resetRegisterUser, saveAuth, errorReset } =
  userSlice.actions;

export const userSelector = (state) => state.register;

export default userSlice.reducer;
