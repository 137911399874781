import React from "react";
import Image from "assets/courseImages/IOMStart.svg";
import Avatar from "common/Avatar";
// css
import "./index.scss";

function DiscussionDetailBody({ data }) {
  return (
    <section className="examDiscussionDetailBody">
      <Avatar size="md-l" />

      <div className="examDiscussionDetailBody__info">
        <div className="examDiscussionDetailBody__info--header">
          <h4>{data?.data.post_by.username}</h4>
          <p>{data?.data.post_at}</p>
        </div>
        <p className="examDiscussionDetailBody__info--body">
          {data?.data.message}
        </p>
        <div className="examDiscussionDetailBody__info--imagesContainer">
          {data?.data.uploadedImage != null ? (
            <img src={data?.data.uploadedImage} alt="img" />
          ) : (
            <div />
          )}
          {/* <img src={Image} alt="img" /> */}
        </div>
      </div>
    </section>
  );
}

export default DiscussionDetailBody;
