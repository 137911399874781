import React, { createContext, useEffect } from "react";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
// css
import "./Dashboard.scss";
import { loginSelector } from "pages/AuthenticationPage/Login/LoginSlice";
import { Redirect } from "react-router-dom";

export const DashboardContext = createContext(null);

function Dashboard() {
  const { isAuthenticated } = useSelector(loginSelector);

  const { data } = useSelector(userPreferenceSelector);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     return <Redirect to="/" />;
  //   }
  // }, [isAuthenticated]);
  const course_name = data?.course_name;
  const course_slug = data?.course_slug;
  const full_name = data?.full_name;
  return (
    <DashboardContext.Provider value={{ course_name, course_slug, full_name }}>
      <div className="dashboard">
        <LeftContent />
        <RightContent />
      </div>
    </DashboardContext.Provider>
  );
}

export default Dashboard;
