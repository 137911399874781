import React from 'react';
import QuestionContainerHeader from './QuestionContainerHeader';
import Line from 'common/Line';
import QuestionsListWithPagination from './QuestionsListWithPagination';
// css
import './index.scss';

const QuestionsContainer = () => {
  return (
    <div className='questionsContainer'>
      <QuestionContainerHeader />
      <Line orientation='horizontal' />

      <QuestionsListWithPagination />
    </div>
  );
};

export default React.memo(QuestionsContainer);
