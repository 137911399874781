import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { baseURL } from './api';

export const baseQuery = fetchBaseQuery({
  // baseUrl: 'http://www.pocketexam.co/api/',
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Access-Control-Allow-Origin', '*');

    const token = getState().auth.token;
    // // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
