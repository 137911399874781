import React, { createContext, useState } from "react";
import HeaderSection from "../HeaderSection";
import QBankCardsSection from "./QBankCardsSection";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
// css
import "./index.scss";

export const QBankContext = createContext(null);

function QBank() {
  const [selectType, setSelectType] = useState("");
  const [selectSortBy, setSelectSortBy] = useState("");
  // console.log('sort by', selectSortBy);

  const { data: userPreference } = useSelector(userPreferenceSelector);
  console.log(userPreference);

  return (
    <QBankContext.Provider value={{ userPreference, selectType, selectSortBy }}>
      <div className="qBank">
        <HeaderSection
          title="QBank"
          setSelectType={setSelectType}
          setSelectSortBy={setSelectSortBy}
        />
        <QBankCardsSection />
      </div>
    </QBankContext.Provider>
  );
}

export default QBank;
