import React, { useState } from 'react';
import {
  courseExamSelector,
  pushAnswer,
} from 'pages/CourseExamPage/courseExamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FilterMediaRoutePattern } from 'utils/regex';
import MathJax from 'mathjax3-react';
// css
import './index.scss';

const Answers = ({ resultcard, data }) => {
  const {
    optiona,
    optionb,
    optionc,
    optiond,
    user_answer,
    answer: main_answer,
  } = data;
  const dispatch = useDispatch();
  const { answers } = useSelector(courseExamSelector);

  const answersList = [
    { id: 0, answer: optiona },
    { id: 1, answer: optionb },
    { id: 2, answer: optionc },
    { id: 3, answer: optiond },
  ];

  const [answersArray] = useState(answersList);

  const handleAnswer = (answer, question) => {
    dispatch(pushAnswer({ answer, question_no: question }));
  };

  let answerImgPath;
  let isAnswerImgPath;
  const answerWithImage = (answer) => {
    isAnswerImgPath = answer.match(FilterMediaRoutePattern);
    answerImgPath = `${URL}${answer.match(FilterMediaRoutePattern)}`;
  };

  const showClassname = (check, ans) => {
    if (resultcard) {
      if (main_answer === ans && user_answer === ans) {
        return 'questionQuizCard__answers--default correctAnswer';
      } else if (user_answer === ans) {
        return 'questionQuizCard__answers--default wrongAnswer';
      } else {
        return 'questionQuizCard__answers--default';
      }
    }
    if (check) return 'questionQuizCard__answers--answer active';

    return 'questionQuizCard__answers--answer';
  };

  const CheckIfSelectedOptionMatchOrNot = (answer) => {
    // NOTE: first find the current question number
    const filteredValue = answers?.find(
      (obj) => obj?.question_no === data?.question_no
    );

    // NOTE: compare answer with filtereed question answer
    const check =
      filteredValue?.answer === ''
        ? false
        : filteredValue?.answer === answer?.toString();

    return check;
  };

  return (
    <div className='questionQuizCard__answers'>
      {answersArray.map(({ answer, id }) => {
        const check = CheckIfSelectedOptionMatchOrNot(answer);

        return (
          <button
            onClick={() => handleAnswer(answer, data.question_no)}
            key={id}
            disabled={resultcard}
            className={`${showClassname(check, answer)}`}
          >
            <MathJax.Formula
              formula={answer.replace(FilterMediaRoutePattern, '')}
            />

            {answerWithImage(answer)}

            {isAnswerImgPath !== null && (
              <img src={answerImgPath} alt={`question-${data.question_no}`} />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default React.memo(Answers);
