import React from "react";
import { Link } from "react-router-dom";
import tu_logo from "assets/icons/tu__logo.svg";
import tickIcon from "assets/icons/tick_icon.svg";
import Line from "common/Line";
import Button from "common/Button";
import AvatarGroups from "common/AvatarGroup";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
// css
import "./index.scss";
import { useHistory } from "react-router-dom";

const bodyData = [
  { id: 0, name: "Authentic Questions" },
  // { id: 1, name: "Assignments and notes" },
  // { id: 2, name: "CSIT Exam Preparation" },
  // { id: 3, name: "CSIT Exam Preparation hello pasdasdasdasd asd asd asd" },
];

const ExamCard = ({ data }) => {
  const history = useHistory();

  const { data: userPreferenceData } = useSelector(userPreferenceSelector);
  const handleContinue = () => {
    history.push(
      `/exam?slug=${userPreferenceData?.course_slug}&university=${data?.university}&model_slug=${data?.slug}`
    );
  };
  return (
    <div className="examCard">
      <Link
        to={`/exam-details?course=${userPreferenceData?.course_slug}&type=${data?.university}&slug=${data?.slug}`}
      >
        <div className="examCard__header">
          <img src={tu_logo} alt="logo" />
          <div className="examCard__header--info">
            <h4>{data.name}</h4>
            <AvatarGroups leaderboard={data.progress.leaderboard} />
          </div>
        </div>

        <div className="examCard__body">
          {bodyData.map((data) => (
            <div key={data.id} className="examCard__body--item">
              <img src={tickIcon} alt="tick-icon" />
              <p>{data.name}</p>
            </div>
          ))}
        </div>
      </Link>
      <Line orientation="horizontal" />
      <div className="examCard__bottomSection">
        <h5>{data.total_question} Total Questions</h5>
        <Button
          className="examCard__bottomSection--btn"
          size="sm"
          variant="contained"
          color="main"
          onClick={handleContinue}
        >
          Start Quiz
        </Button>
      </div>
    </div>
  );
};

export default ExamCard;
