import { CourseExamPageContext } from 'contextAPI/CourseExamPageContext';
import React, { useContext } from 'react';
import Timer from '../QuestionsContainer/QuestionContainerHeader/Timer';
import './index.scss';

const ShowTimerForMediumScreen = () => {
  const { totalAnswered, total_questions } = useContext(CourseExamPageContext);
  return (
    <div className='sticky-timer'>
      <p>
        {totalAnswered}/{total_questions}
      </p>
      <Timer stickyheader={true} />
    </div>
  );
};

export default ShowTimerForMediumScreen;
