import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
// css
import "./index.scss";

const TextArea = ({ className, size, ...rest }) => {
  return (
    <textarea
      className={classNames(className, "textarea", {
        [`textarea--${size}`]: size,
      })}
      placeholder="Type here. Drag or paster images."
      {...rest}
    />
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md"]),
};

export default TextArea;
