import Avatar from "common/Avatar";
import React from "react";
import { useState } from "react";
import "./index.scss";
import renderHTML from "react-render-html";
const AllNotificationCard = ({ notification, id }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="notificationCard" id={id}>
      <Avatar size="sm" />
      <div className="notificationCard__details">
        <h5>{renderHTML(notification.title)}</h5>
        <p className="notificationCard__details--time">
          {notification.created}
        </p>
        <div className="notificationCard__details--body">
          {/* {renderHTML(notification.body)} */}
          <p>
            {/* {showMore */}
            {/* ?  */}
            {renderHTML(notification.body)}
            {/* : `${notification.body.substring(0, 40)}...`} */}
          </p>
          {/* <button
            className="notificationCard__details--body-seeMoreBtn"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? null : "See more"}
          </button> */}
        </div>
        <div className="notificationCard__details--image">
          {notification.image !== null && (
            <img src={notification.image} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllNotificationCard;
