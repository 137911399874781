import Button from 'common/Button';
import Input from 'common/Input/Input';
import React from 'react';
import './index.scss';

const ForgotPasswordForm = ({ changeSuccess }) => {
  return (
    <form className='forgotPasswordForm'>
      <Input
        className='forgotPasswordForm__input'
        type='email'
        size='md'
        placeholder='First Name'
      />
      <Button
        className='forgotPasswordForm__btn'
        color='gradient'
        variant='contained'
        size='md'
        onClick={changeSuccess}
      >
        reset password
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
