import React from 'react';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  rating: {
    fontSize: '2.4rem',
    color: '#FFC812',
  },
});

const Ratings = () => {
  const classes = useStyles();
  return (
    <Rating
      className={classes.rating}
      name='customized-empty'
      defaultValue={2}
      precision={0.5}
    />
  );
};

export default Ratings;
