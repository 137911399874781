import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "app/baseQuery";

export const quizDetailsApi = createApi({
  reducerPath: "quizDetailsApi",
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getQuizDetails: build.query({
      query: ({ courseslug, slug, university }) => ({
        url: `/admin/test-series-info-details?courseslug=${courseslug}&institute=pocket-exam&slug=${slug}&university=${university}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetQuizDetailsQuery } = quizDetailsApi;
