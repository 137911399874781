import React, { memo } from 'react';
import './index.scss';

const Container = ({ children, style }) => {
  return (
    <div style={style} className='container-main'>
      {children}
    </div>
  );
};

export default memo(Container);
