import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { instance as api } from 'app/api';

export const getAllNotifications = createAsyncThunk(
  'notifications/all',
  async ({ page, slug }, thunkAPI) => {
    try {
      const res = await api.get(
        `/admin/notification?slug=${slug}&institute=pocket-exam&page=${page}`
      );
      const data = await res.data;
      return data;
    } catch (e) {
      // console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getActivityNotifications = createAsyncThunk(
  'notifications/activity',
  async ({ page, slug }, thunkAPI) => {
    try {
      const res = await api.get(
        `/admin/activity?page=${page}&institute=pocket-exam&slug=${slug}`
      );
      const data = await res.data;
      return data;
    } catch (e) {
      // console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const notificationsSlice = createSlice({
  name: 'userLogin',
  initialState: {
    data: [],
    isFetching: false,
    errorMessage: {},
    error: false,
  },
  reducers: {},
  extraReducers: {
    [getAllNotifications.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.isFetching = false;
      state.errorMessage = {};
      state.error = false;
    },
    [getAllNotifications.pending]: (state, _) => {
      state.isFetching = true;
      state.errorMessage = {};
      state.error = false;
    },
    // errorMessage handling
    [getAllNotifications.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload;
      state.error = true;
    },
    [getActivityNotifications.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.isFetching = false;
      state.errorMessage = {};
      state.error = false;
    },
    [getActivityNotifications.pending]: (state, _) => {
      state.isFetching = true;
      state.errorMessage = {};
      state.error = false;
    },
    // errorMessage handling
    [getActivityNotifications.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload;
      state.error = true;
    },
  },
});

export const notificationsSelector = (state) => state.notifications;

export default notificationsSlice.reducer;
