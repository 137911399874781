import React from 'react';
import Carousel from 'react-elastic-carousel';
import {
  breakPoint,
  breakPointsForAdvertisementSlider,
  breakPointsForYoutubeSlider,
  myArrow,
} from './styles';

const CarouselSlider = ({
  children,
  itemsToShow,
  itemPadding = [0, 10],
  isYoutubeSlider,
  isAdvertisement,
}) => {
  return (
    <Carousel
      pagination={false}
      itemsToShow={itemsToShow}
      renderArrow={myArrow}
      enableAutoPlay
      autoPlaySpeed={2000}
      breakPoints={
        isYoutubeSlider
          ? breakPointsForYoutubeSlider
          : isAdvertisement
          ? breakPointsForAdvertisementSlider
          : breakPoint
      }
      itemPadding={isAdvertisement ? [0, 0] : itemPadding}
    >
      {children}
    </Carousel>
  );
};

export default CarouselSlider;
