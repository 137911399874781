import React, { useEffect } from "react";
import CancelButton from "common/CancelButton";
import CircularProgress from "common/CircularProgress";
import { useGetCourseListQuery } from "app/services/courseListApi";
import { useUpdateUserPreferenceMutation } from "app/services/userPreferenceApi";
// css
import "./CategoryModal.scss";
import { useHistory } from "react-router-dom";

function CategoryModal({ handleClose, checkData }) {
  const { data: courseList, isLoading } = useGetCourseListQuery({
    refetchOnMountOrArgChange: 60 * 60,
  });
  const [updateUserPreference, { isSuccess }] =
    useUpdateUserPreferenceMutation();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);
  const history = useHistory();
  const handleSelectCategory = async (category) => {
    try {
      await updateUserPreference({
        slug: category.slug,
        institute: "pocket-exam",
      }).unwrap();
      history.push("/");
    } catch (error) {
      console.log("error occured", error);
    }
  };

  if (isLoading) {
    return (
      <div className="categoryModal">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size="md" />;
        </div>
      </div>
    );
  }

  return (
    <div className="categoryModal">
      <div className="categoryModal__header">
        <div className="categoryModal__header--title">
          <h5>Choose the Category you want to view today.</h5>
          <p>You can always switch to other exam categories anytime.</p>
        </div>

        {checkData.is_found === true ? (
          <CancelButton
            className="categoryModal__header--crossIcon"
            onClick={handleClose}
          />
        ) : (
          <div />
        )}
      </div>
      <div className="categoryModal__body">
        {courseList?.course?.map((category) => {
          return (
            <div
              key={category.id}
              onClick={() => handleSelectCategory(category)}
            >
              <div className="categoryModal__body--card">
                <img src={category.image} alt={`${category.name}-img`} />
                <h5 className="categoryModal__body--card-title">
                  {category.name}
                </h5>
                <p className="categoryModal__body--card-totalStudents">
                  {category.total_students} Student attempts{" "}
                </p>
                <p className="categoryModal__body--card-time">
                  {category.time} | {category.total_questions} Questions
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CategoryModal;
