import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  answers: [],
};

export const courseExamSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    pushAnswer: (state, action) => {
      const objIndex = state.answers.findIndex(
        (obj) => obj.question_no === action.payload.question_no
      );
      if (!state.answers[objIndex]) {
        state.answers.push(action.payload);
      } else {
        state.answers[objIndex].answer = action.payload.answer;
      }
    },
    resetAnswer: (state, _) => {
      state.answers = [];
    },
  },
});

export const { pushAnswer, resetAnswer } = courseExamSlice.actions;

export const courseExamSelector = (state) => state.courseExam;

export default courseExamSlice.reducer;
