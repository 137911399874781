import React from "react";
import facebookIcon from "assets/icons/facebook.svg";
import twitterIcon from "assets/icons/twitter.svg";
import instagramIcon from "assets/icons/instagram.svg";
// css
import "./index.scss";

function FollowAcademy() {
  return (
    <div className="followAcademy">
      <p className="followAcademy__heading">Follow Pocket Exam</p>

      <div className="followAcademy__body">
        <button>
          <div className="followAcademy__body--content">
            <img src={facebookIcon} alt="facebook-Icon" />
            <p className="followAcademy__body--content-facebook">12k</p>
          </div>
        </button>
        <button>
          <div className="followAcademy__body--content">
            <img src={twitterIcon} alt="twitter-Icon" />
            <p className="followAcademy__body--content-twitter">4k</p>
          </div>
        </button>
        <button>
          <div className="followAcademy__body--content">
            <img src={instagramIcon} alt="instagram-Icon" />
            <p className="followAcademy__body--content-instagram">22k</p>
          </div>
        </button>
      </div>
    </div>
  );
}

export default FollowAcademy;
