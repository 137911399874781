import lifeMentoringIcon from 'assets/icons/benefitsForLearners/lifementoring.svg';
import selfDevelopmentIcon from 'assets/icons/benefitsForLearners/self-development.svg';
import consultancyIcon from 'assets/icons/benefitsForLearners/consultancy.svg';
import remoteLearningIcon from 'assets/icons/benefitsForLearners/remote-learning.svg';

export const data = [
  {
    id: 0,
    icon: lifeMentoringIcon,
    title: 'Life Mentoring',
    decription: `Everyone needs at least one great life mentor in their lifetime. They'll give you advice and inspiration that will completely change your future.`,
  },
  {
    id: 1,
    icon: selfDevelopmentIcon,
    title: 'Self Development',
    decription: `Get some soul healing and guidance for your future career and vocational directions.`,
  },
  {
    id: 2,
    icon: consultancyIcon,
    title: 'Ask Consultancy',
    decription: `Get some soul healing and guidance for your future career and vocational directions.`,
  },
  {
    id: 3,
    icon: remoteLearningIcon,
    title: 'Remote Learning',
    decription: `Get some soul healing and guidance for your future career and vocational directions.`,
  },
];
