import React, { useContext } from 'react';
import { minToHr } from 'utils/timeConverter';
import { ReviewAnswerContext } from '../index';
import './index.scss';

const ReviewAnswerHeader = () => {
  const { data } = useContext(ReviewAnswerContext);
  return (
    <section className='reviewAnswerHeader'>
      <div className='reviewAnswerHeader__col-1'>
        <h1>{data?.title}</h1>
        <h4>
          Total Score: <span>{data?.obtained_marks}</span>
        </h4>
      </div>
      <div className='reviewAnswerHeader__col-2'>
        <h4>
          Exam Time: <span>{minToHr(data?.time)} hrs</span>
        </h4>
        <h4>
          Full marks: <span>{data?.total_marks}</span>
        </h4>
        <h4>
          pass marks: <span>{data?.pass_marks}</span>
        </h4>
      </div>
    </section>
  );
};

export default ReviewAnswerHeader;
