import Container from 'common/Container';
import React from 'react';
import CourseDetailsTitle from '../CourseDetailsTitle';
import SectionContainer from '../SectionContainer';

import mocktestIcon from 'assets/icons/mockTest.svg';
import magicIcon from 'assets/icons/magic.svg';
import champIcon from 'assets/icons/champ.svg';
import rapidLearningIcon from 'assets/icons/rapidLearning.svg';
import DreamCourseCard from './DreamCourseCard';
// css
import './index.scss';

const courseList = [
  {
    id: 0,
    icon: mocktestIcon,
    name: 'mock exams',
    desc: `Everyone needs at least one great life mentor in their lifetime. They'll give you advice and inspiration that will completely change your future.`,
  },
  {
    id: 1,
    icon: magicIcon,
    name: 'We work some magic',
    desc: `Everyone needs at least one great life mentor in their lifetime.`,
  },
  {
    id: 2,
    icon: champIcon,
    name: 'Study like a champ.',
    desc: `Everyone needs at least one great life mentor in their lifetime. They'll give you advice and inspiration that will completely change your future.`,
  },
  {
    id: 3,
    icon: rapidLearningIcon,
    name: 'Raid Learning',
    desc: `Everyone needs at least one great life mentor in their lifetime. They'll give you advice and inspiration that will completely change your future.`,
  },
];

const DreamCourseFeatures = () => {
  return (
    <Container>
      <SectionContainer>
        <CourseDetailsTitle
          title='Dream Course Features'
          subtitle='INCLUDES EVERYTHING YOU NEED TO IOM EXAMS'
        />

        <div className='dreamCourseFeatures__flex'>
          {courseList.map((course) => (
            <DreamCourseCard key={course.id} data={course} />
          ))}
        </div>
      </SectionContainer>
    </Container>
  );
};

export default DreamCourseFeatures;
