import React from 'react';

import './index.css';

import primaryCircle from 'assets/icons/aboutus/circle-primary-color.svg';
import pinkCircle from 'assets/icons/aboutus/circle-pink-color.svg';
import yellowCircle from 'assets/icons/aboutus/circle-yellow-color.svg';
import greyCircle from 'assets/icons/aboutus/circle-grey-color.svg';
import secondayCircle from 'assets/icons/aboutus/circle-secondary-color.svg';

const BackgroundImageSection = () => {
  return (
    <div className='aboutusbanner__img'>
      <section className='aboutusbanner__img-left'>
        <img className='aboutusbanner__img-1' src={primaryCircle} alt='' />
        <img className='aboutusbanner__img-2' src={pinkCircle} alt='' />
        <img className='aboutusbanner__img-3' src={yellowCircle} alt='' />
      </section>
      <section className='aboutusbanner__img-right'>
        <img className='aboutusbanner__img-4' src={secondayCircle} alt='' />
        <img className='aboutusbanner__img-5' src={greyCircle} alt='' />
        <img className='aboutusbanner__img-6' src={pinkCircle} alt='' />
      </section>
    </div>
  );
};

export default BackgroundImageSection;
