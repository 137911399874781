import React from "react";
import shareIcon from "assets/icons/share__icon.svg";
import MenuDropdown from "common/MenuDropdown";
import ShareHover from "./ShareHover";

const ShareBtn = ({ postid }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <button
        className="sharereplyreport__left--greyBtn"
        onClick={handleOpen}
        ref={anchorRef}
      >
        <img src={shareIcon} alt="share-icon" />
        share
      </button>
      <MenuDropdown
        share="true"
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
      >
        <ShareHover handleClose={handleClose} postid={postid} />
      </MenuDropdown>
    </>
  );
};

export default ShareBtn;
