import React, { useContext } from "react";
import ExamCard from "common/ExamCard";
// import loadmore from 'assets/icons/loadmore.svg';
import { useGetQuestionSetsQuery } from "app/services/questionSetApi";
import CircularProgress from "common/CircularProgress";
import { QBankContext } from "../index";
// css
import "./index.scss";

const QBankCardsSection = () => {
  const { userPreference, selectType } = useContext(QBankContext);
  const { data, isLoading, isFetching } = useGetQuestionSetsQuery(
    {
      slug: userPreference?.course_slug,
      university: selectType,
    },
    { refetchOnMountOrArgChange: 5 }
  );

  if (isLoading) {
    return <CircularProgress size="lg" />;
  }
  return (
    <section className={`qBankCardsSection  ${isFetching ? "disabled" : ""}`}>
      <div className="qBankCardsSection__cardsBody">
        {data?.testSeries?.map((data, index) => (
          <ExamCard key={index} data={data} />
        ))}
      </div>

      {/* <div className='qBankCardsSection__loadMoreSection'>
        <button>
          Load More
          <img src={loadmore} alt='loadmore__icon' />
        </button>
      </div> */}
    </section>
  );
};

export default QBankCardsSection;
