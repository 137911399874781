import Avatar from 'common/Avatar';
import React from 'react';
import { useState } from 'react';
import './index.scss';

const ActivityNotificationCard = ({ notification, id }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className='activityNotificationCard' id={id}>
      <Avatar src={notification.user.image_uri} size='sm' />
      <div className='activityNotificationCard__details'>
        <h5>{notification.title}</h5>
        <p className='activityNotificationCard__details--time'>
          {notification.created}
        </p>
        <div className='activityNotificationCard__details--body'>
          <p>
            {showMore
              ? notification.body
              : `${notification.body.substring(0, 40)}...`}
          </p>
          <button
            className='activityNotificationCard__details--body-seeMoreBtn'
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? null : 'See more'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivityNotificationCard;
