import React from "react";
import RecientQuizImage from "assets/images/recient_quiz.svg";
import Line from "common/Line";
import Button from "common/Button";
// css
import "./index.scss";
import { Link, useHistory } from "react-router-dom";

function RecientQuizesCard({ data, userPreference }) {
  const totalStudents = data?.progress?.leaderboard?.length;
  const history = useHistory();

  const handleStartAgain = () => {
    history.push(
      `/exam?slug=${userPreference?.course_slug}&university=${data?.university}&model_slug=${data?.slug}`
    );
  };

  return (
    <Link
      to={`/exam-details?course=${userPreference?.course_slug}&type=${data?.university}&slug=${data?.slug}`}
    >
      <div className="RecientQuizesCard">
        <div className="RecientQuizesCard__image">
          <img src={RecientQuizImage} alt="RecientQuizImage" />
        </div>
        <div className="RecientQuizesCard__content">
          <h4>{data?.name}</h4>
          <p>{totalStudents} Student attempts</p>

          <div className="RecientQuizesCard__content--minusQuestions">
            <p>{data?.time} mins</p>
            <Line orientation="vertical" />
            <p>{data?.total_question} Questions</p>
          </div>
          <Button
            onClick={handleStartAgain}
            color="main"
            variant="contained"
            size="sm"
          >
            Start Again
          </Button>
        </div>
      </div>
    </Link>
  );
}

export default RecientQuizesCard;
