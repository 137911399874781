import { useCallback, useEffect, useState } from "react";

const useIntersectionObserver = ({
  querySelector,
  list,
  next,
  page,
  setPage,
}) => {
  const [observedPost, setObservedPost] = useState("");
  let option = useCallback(
    () => [
      {
        root: window.document.querySelector(querySelector),
        rootMargin: "0px",
        threshold: 0.1,
      },
    ],
    [querySelector]
  );

  const observeElement = useCallback(
    (element) => {
      if (!element) return;

      // if last element id is observed or intersecting then it will increase page count or if next page is null then page count will remain as previous page count
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true) {
          if (next === null) return page;
          setPage((prev) => prev + 1);
          observer.unobserve(element);
        }
      }, option);

      observer.observe(element);
    },
    [next, option, page, setPage]
  );

  useEffect(() => {
    if (list.length === 0) return;
    const id = list[list.length - 1].id;

    // NOTE: compares list items last id and observed post id
    if (id !== observedPost) {
      setObservedPost(id);
      // will select the last element with id
      observeElement(document.getElementById(id));
    }
  }, [list, observeElement, observedPost]);
  // return;
};

export default useIntersectionObserver;
