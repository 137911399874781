import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { instance as api } from 'app/api';

export const getCoursesList = createAsyncThunk(
  'header/category-modal',
  async (_, thunkAPI) => {
    try {
      const res = await api.get('admin/course-list?institute=pocket-exam');
      const data = await res.data;
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getCoursesListSlice = createSlice({
  name: 'courses list',
  initialState: {
    data: [],
    isFetching: false,
    errorMessage: {},
    error: false,
  },
  reducers: {},
  extraReducers: {
    [getCoursesList.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.isFetching = false;
      state.errorMessage = {};
      state.error = false;
    },
    [getCoursesList.pending]: (state, _) => {
      state.isFetching = true;
      state.errorMessage = {};
      state.error = false;
    },
    [getCoursesList.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload;
      state.error = true;
    },
  },
});

export const getCoursesListSelector = (state) => state.getCoursesList;

export default getCoursesListSlice.reducer;
