import React from 'react';
import './index.scss';

const ResultItem = ({ icon, name, value }) => {
  return (
    <div className='resultItem'>
      <img src={icon} alt={`${name}-icon`} />
      <div className='resultItem__info'>
        <h4>{name}</h4>
        <p>{value}</p>
      </div>
    </div>
  );
};

export default ResultItem;
