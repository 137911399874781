import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/baseQuery';

export const recentQuizApi = createApi({
  reducerPath: 'recentQuizApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getRecentQuiz: build.query({
      query: ({ slug }) => ({
        url: `/admin/recommendation?slug=${slug}&institute=pocket-exam`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetRecentQuizQuery } = recentQuizApi;
