import React from 'react';
import QuestionAnalysis from './QuestionAnalysis';
import QuestionsLeft from './QuestionsLeft';

const TimerSection = () => {
  return (
    <div>
      <QuestionsLeft />
      <QuestionAnalysis />
    </div>
  );
};

export default TimerSection;
