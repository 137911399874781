import React from "react";
import FreeClasses from "./FreeClasses";
import AttemptExam from "./AttemptExam/AttemptExam";
import RecentQuizes from "./RecentQuizes";
import RecentlyRelease from "./RecentlyRelease/RecentlyRelease";
import Recommended from "./Recommended/Recommended";
import IOMSuperstart from "./IOMSuperstart/IOMSuperstart";
import Leaderboard from "./Leaderboard/Leaderboard";
import HeaderContent from "./HeaderContent";
import Line from "common/Line";
import AdvertisementSection from "./AdvertisementSection";
import { useSelector } from "react-redux";
import { loginSelector } from "pages/AuthenticationPage/Login/LoginSlice";
// css
import "./index.scss";

function LeftContent() {
  const { isAuthenticated } = useSelector(loginSelector);

  return (
    <div className="leftContent">
      <HeaderContent />
      <Line orientation="horizontal" />
      {isAuthenticated && <AdvertisementSection />}
      {/* <FreeClasses /> */}
      {/* <AttemptExam /> */}
      <RecentQuizes />
      {/* <RecentlyRelease /> */}
      {/* <Recommended /> */}
      {/* <IOMSuperstart /> */}
      <Leaderboard />
    </div>
  );
}

export default LeftContent;
