import React from 'react';
import './index.scss';

const DreamCourseCard = ({ data }) => {
  return (
    <div className='dreamCourseCard'>
      <img src={data.icon} alt={`${data.name}-icon`} />
      <h4>{data.name}</h4>
      <p>{data.desc}</p>
    </div>
  );
};

export default DreamCourseCard;
