import React from 'react';
import { URL } from 'app/api';
import { FilterMediaRoutePattern } from 'utils/regex';
import MathJax from 'mathjax3-react';
import Answers from './Answers';
import parse from 'html-react-parser';
// css
import './index.scss';

const QuestionQuizCard = ({ data, resultcard }) => {
  const isImagePathAvailable = data.question.match(FilterMediaRoutePattern);
  const imgPath = `${URL}${data.question.match(FilterMediaRoutePattern)}`;

  return (
    <div className={`questionQuizCard ${data.optiona === 'null' && 'null'}`}>
      <div
        className={`questionQuizCard__question ${
          data.optiona === 'null' && 'null'
        }`}
      >
        <span>{data.optiona === 'null' ? null : `Q.${data.question_no}`}</span>

        <MathJax.Formula
          formula={parse(
            data.question.replace(FilterMediaRoutePattern, '').toString()
          )}
        />

        {/* // const math2 = String.raw`The number of partial fraction of \({5x+4}\over {x^2 + 3x - 4}\) is/are`; */}

        {isImagePathAvailable !== null && (
          <img src={imgPath} alt={`question-${data.question_no}`} />
        )}
      </div>

      {data.optiona === 'null' ? null : (
        <Answers data={data} resultcard={resultcard} />
      )}
    </div>
  );
};

export default React.memo(QuestionQuizCard);
