import Button from "common/Button";
import Input from "common/Input/Input";
import Select from "common/Select";
import TextArea from "common/TextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlinePlusSquare } from "react-icons/ai";
// css
import "./index.scss";
import * as Yup from "yup";
import { usePostDiscussionMutation } from "app/services/discussionForumApi";
import { useSelector } from "react-redux";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import ExamDiscussionTable from "../../../ExamDiscussionTable";

const InputContainer = ({ handleClose, selectdata, setpostSortBy }) => {
  const schema = Yup.object().shape({
    title: Yup.string().email("Invalid email").required("Email is required."),
    password: Yup.string()
      .min(8, "Must be 8 characters or more")
      .max(32, "Must not be more than 32 characters")
      .required("Password is required."),
  });
  const {
    control,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data } = useSelector(userPreferenceSelector);
  const [selectSortBy, setSelectSortBy] = useState("General");
  const [postDiscussion, { isLoading }] = usePostDiscussionMutation({});
  let slug = data.course_slug;
  const handleSubmit = useCallback(() => {
    let message = document.getElementById("message").value;

    postDiscussion({ slug, selectSortBy, message })
      .unwrap()
      .then((res) => {
        handleClose();
        setpostSortBy(res);
        // ExamDiscussionTable();
        // console.log("fafa");
        return res;
      })
      .catch((err) => console.log(err));
  }, [postDiscussion, selectSortBy, slug, handleClose]);
  return (
    <div className="inputContainer">
      <header>
        <AiOutlinePlusSquare />
        <h4>Create a new Topic</h4>
      </header>

      <div className="inputContainer--body">
        {/* <Input
          size="md"
          placeholder="Topic Title: Please keep these short and descriptive"
          name="title"
          control={control}
        /> */}
        <div className="inputContainer--flex">
          <Select
            className="inputContainer--flex-select"
            size="md"
            options={selectdata.slice(1)}
            setValue={setSelectSortBy}
          />
          {/* <Select
            className="inputContainer--flex-select"
            size="md"
            options={options}
          /> */}
        </div>
        <TextArea id="message" size="md" />
        <div className="inputContainer--btnGroups">
          <Button
            className="inputContainer--btnGroups-btn"
            variant="outlined"
            color="sub"
            size="md"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="inputContainer--btnGroups-btn"
            color="main"
            size="md"
            variant="contained"
            onClick={() => handleSubmit()}
            isLoading={isLoading}
          >
            Create topic
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InputContainer;
