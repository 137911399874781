import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "app/baseQuery";

export const discussionCommentApi = createApi({
  reducerPath: "discussionCommentApi",
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getComment: build.query({
      query: ({ id }) => ({
        url: `/admin/forum-comment?id=${id}`,
        method: "GET",
      }),
    }),
    postComment: build.mutation({
      query: ({ slug, value, postid }) => ({
        url: `/admin/forum-comment?slug=${slug}&institute=pocket-exam`,
        method: "POST",
        body: { message: value, postid: postid },
      }),
    }),
  }),
});

export const { useGetCommentQuery, usePostCommentMutation } =
  discussionCommentApi;
export default discussionCommentApi;
