import Container from 'common/Container';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormContainer from '../FormContainer';
import ForgotPasswordForm from './ForgotPasswordForm';
// css
import './index.scss';

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const changeSuccess = () => {
    setSuccess(true);
  };

  return (
    <Container>
      <div style={{ paddingTop: '5rem' }}>
        <FormContainer style={{ maxWidth: '50rem', margin: 'auto' }}>
          {success ? (
            <div className='success-route'>
              <p onClick={() => history.push('/changeNewPassword/sadadasd')}>
                Check your email to get a link to create a new password.
              </p>
            </div>
          ) : (
            <div className='forgotPassword'>
              <h1>Get Your Password</h1>
              <p>
                Lost your password? Please enter your username or email address.
                You will receive a link to create a new password via email.
              </p>
              <ForgotPasswordForm changeSuccess={changeSuccess} />
            </div>
          )}
        </FormContainer>
      </div>
    </Container>
  );
};

export default ForgotPassword;
