import React from "react";
import DefaultImage from "assets/profile/user-default.png";
// css
import "./index.scss";

const LeaderboardCard = ({ data }) => {
  return (
    <div className="leaderboardCard">
      <div className="leaderboardCard__imageContainer">
        <img
          src={data?.image_url === "" ? DefaultImage : data?.image_url}
          alt={`${data?.user?.username}-img`}
        />
      </div>
      <p className="leaderboardCard__name">{data?.username}</p>
      <p className="leaderboardCard__score">Score - {data?.correct}</p>
    </div>
  );
};

export default LeaderboardCard;
