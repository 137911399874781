import React from "react";
import BackgroundImageSection from "./BackgroundImageSection";

import "./index.css";

const details = [
  {
    id: 1,
    numbers: "1k",
    title: "learners",
  },
  {
    id: 2,
    numbers: "20k+",
    title: "questions",
  },
  {
    id: 3,
    numbers: "4",
    title: "courses",
  },
  {
    id: 4,
    numbers: "5k+",
    title: "course enrollments",
  },
];

const AboutUsBanner = () => {
  return (
    <div className="aboutusbanner">
      {/* background images */}
      <BackgroundImageSection />

      {/* main banner body */}
      <h1 className="aboutusbanner__title">
        Online <span>Entrance Preparation</span> for Cracking Entrance.
      </h1>
      <p className="aboutusbanner__intro">
        Pocket Exam is a learning marketplace that provides a Preparation
        Resources for cracking Entrance of Courses and allow them to learn
        subjects of interest in depth knowledge.
        <br />
        We are group of enthusiast students, teachers, professional who came
        together to bring a change in educational program, as a result of which
        Pocket Exam focuses on building a Entrance Question of difference
        courses that shall be sellable in market upfront, students enrolled in
        the courses provided by Pocket Exam shall carry the values that shall be
        important for cracking entrance.
        <br />
        Along with the Entrance, the students shall be able to learn about the
        career prospects of the relevant courses, prepare for the market and
        launch their career.
      </p>

      {/* total learners, instructors, courses, course enrollment section */}
      <section className="aboutusbanner__section">
        {details.map((detail, index) => (
          <div className="aboutusbanner__section-detail" key={index}>
            <h1>{detail.numbers}</h1>
            <p>{detail.title}</p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default AboutUsBanner;
