import React from 'react';

import { data } from './data';

import './index.css';

const BenefitsCards = () => {
  return (
    <div className='benefitsCards'>
      {data.map((card) => (
        <div className='benefitsCards-card'>
          <img src={card.icon} alt={`${card.title}-icon`} />
          <h5>{card.title}</h5>
          <p>{card.decription}</p>
        </div>
      ))}
    </div>
  );
};

export default BenefitsCards;
