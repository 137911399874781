import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    auth: false,
    register: false,
    login: false,
    forgotPassword: false,
    verify: false,
    regComplete: false,
    reset: false,
    newPassword: false,
    passwordChanged: false,
  },
  reducers: {
    // toggle Auth Modal
    toggleAuth: (state) => {
      state.auth = !state.auth;
    },
    toggleAuthReset: (state) => {
      state.auth = false;
    },
    // reigster modal
    showRegister: (state) => {
      state.register = true;
    },
    unshowRegister: (state) => {
      state.register = false;
    },
    // login modal
    showLogin: (state) => {
      state.login = true;
    },
    unshowLogin: (state) => {
      state.login = false;
    },
    // password modal
    showForgotPassword: (state) => {
      state.forgotPassword = true;
    },
    unshowForgotPassword: (state) => {
      state.forgotPassword = false;
    },
    // verify modal
    showVerify: (state) => {
      state.verify = true;
    },
    unShowVerify: (state) => {
      state.verify = false;
    },
    // registration Complete modal
    showRegComplete: (state) => {
      state.regComplete = true;
    },
    unshowRegComplete: (state) => {
      state.regComplete = false;
    },
    // reset password modal
    showResetPassword: (state) => {
      state.reset = true;
    },
    unshowResetPassword: (state) => {
      state.reset = false;
    },
    // new password modal
    showNewPassword: (state) => {
      state.newPassword = true;
    },
    unshowNewPassword: (state) => {
      state.newPassword = false;
    },
    // password changed modal
    showPasswordChanged: (state) => {
      state.passwordChanged = true;
    },
    unshowPasswordChanged: (state) => {
      state.passwordChanged = false;
    },
  },
});

export const {
  toggleAuth,
  toggleAuthReset,
  showRegister,
  unshowRegister,
  showLogin,
  unshowLogin,
  showForgotPassword,
  unshowForgotPassword,
  showVerify,
  unShowVerify,
  showRegComplete,
  unshowRegComplete,
  showResetPassword,
  unshowResetPassword,
  showNewPassword,
  unshowNewPassword,
  showPasswordChanged,
  unshowPasswordChanged,
} = appSlice.actions;

export const selectAuth = (state) => state.app.auth;
export const selectRegister = (state) => state.app.register;
export const selectLogin = (state) => state.app.login;
export const selectForgotPassword = (state) => state.app.forgotPassword;
export const selectVerify = (state) => state.app.verify;
export const selectRegComplete = (state) => state.app.regComplete;
export const selectResetPassword = (state) => state.app.reset;
export const selectNewPassword = (state) => state.app.newPassword;
export const selectPasswordChanged = (state) => state.app.passwordChanged;

export default appSlice.reducer;
