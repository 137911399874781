import URL from "app/api";
// firebase auth keys
const API_KEY = "AIzaSyDQepywoUllhbuO_0DIyltcl4pAOoBK3CI";
const AUTH_DOMAIN = "pocket-exam-fd852.firebaseapp.com";
const PROJECT_ID = "pocket-exam-fd852";
const STORAGE_BUCKET = "pocket-exam-fd852.appspot.com";
const MESSAGING_SENDER_ID = "394993461476";
const APP_ID = "1:394993461476:web:440dfca914677d6883a4f1";

export {
  URL,
  API_KEY,
  AUTH_DOMAIN,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  APP_ID,
};

// JWT_KEYS FOR ZOOM
// export const API_KEY = "TUKLwflYRVegp0bOzLNLHA";
// export const API_SECRET = "KIvz94JfgLiDUeKBQRizBzWibtpoB2kX4nRH";
// export const JWT_TOKEN =
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6IlRVS0x3ZmxZUlZlZ3AwYk96TE5MSEEiLCJleHAiOjE2MjA4OTkxODksImlhdCI6MTYyMDg5Mzc4OX0.Jxptul3iueDcSD240yNCpVUKQ1BTj85QgfDd5unBbbo";
