import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import discussionCommentApi, {
  useGetCommentQuery,
} from "app/services/discussionCommentApi";
import discussionForumApi from "app/services/discussionForumApi";
import Line from "common/Line";
import React, { Fragment, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExamDiscussionContext } from "../..";
import CommentCard from "./CommentCard";
// css
import "./index.scss";

function DiscussionCommentSection({ query, commentSortBy }) {
  const dispatch = useDispatch();
  const { data: userPreference } = useSelector(userPreferenceSelector);
  const { subjectSortBy } = useContext(ExamDiscussionContext);
  const { data, isFetching } = useGetCommentQuery(
    {
      id: query.get("id"),
    },
    { refetchOnMountOrArgChange: 60 }
  );
  useEffect(() => {
    dispatch(
      discussionCommentApi.endpoints.getComment.initiate(
        {
          id: query.get("id"),
        },
        { subscribe: false, forceRefetch: true }
      )
    );
    dispatch(
      discussionForumApi.endpoints.getDiscussion.initiate(
        {
          slug: userPreference?.course_slug,
          tag:
            typeof subjectSortBy == "undefined" || subjectSortBy === "all"
              ? "All"
              : subjectSortBy,
          page: 1,
          institute: "pocket-exam",
        },
        { subscribe: false, forceRefetch: true }
      )
    );
  }, [commentSortBy]);
  return (
    <section className="discussionCommentSection">
      {data?.results.map((data, index) => (
        <Fragment key={index}>
          <CommentCard key={index} comments={data} />
          <Line orientation="horizontal" />
        </Fragment>
      ))}
    </section>
  );
}

export default DiscussionCommentSection;
