import Button from "common/Button";
import Container from "common/Container";
import FormContainer from "../FormContainer";
// css
import "./index.scss";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useDispatch, useSelector } from "react-redux";
// css
// assets
// import CloseIcon from "assets/icons/Close.svg";
// react redux
import { toggleAuth, toggleAuthReset, unShowVerify } from "../appSlice";
import {
  resendEmailVerification,
  resetRegisterUser,
  userSelector,
} from "../Register/userSlice";
// imports
// import GifLoader from "../../LoaderScreen/GifLoader/GifLoader";
const Verify = () => {
  const [email, setEmail] = useState("");
  const [playing, setPlaying] = useState(true);

  const dispatch = useDispatch();

  // prevents user from page reload
  window.onbeforeunload = (event) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = "Do not reload this page";
    }
    return "";
  };

  const history = useHistory();

  const { userRegister, userData, isFetching, auth, status } =
    useSelector(userSelector);

  useEffect(() => {
    userData?.access_token && history.push("/");
    if (status === 200) {
      setEmail(auth);
      dispatch(resendEmailVerification({ email }));
    }
    // !userRegister?.email && history.push('/');
  }, [history, userData, userRegister, auth, status, dispatch, email]);

  const closeVerify = () => {
    history.push("/");
    dispatch(resetRegisterUser());
    dispatch(unShowVerify());
    dispatch(toggleAuth());
    dispatch(toggleAuthReset());
  };

  const resendEmailhandler = async () => {
    if (email !== "") {
      await dispatch(resendEmailVerification({ email }));
    }

    if (userRegister?.email) {
      const emailRegister = userRegister?.email;
      await dispatch(resendEmailVerification({ emailRegister }));
    }

    setPlaying(true);
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      const el = document.getElementById("timer-wrapper");
      el.style.display = "none";
      setPlaying(false);
    }
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };
  return (
    <Container>
      <div style={{ paddingTop: "5rem" }}>
        <FormContainer style={{ maxWidth: "50rem", margin: "auto" }}>
          <div className="verify">
            <h1>Please verify your email</h1>
            <div className="verify__subtitle">
              <p>
                Please check your email for confirmation. You're almost there!,
                we have sent you an email to
              </p>
            </div>
            <h3 className="verify__email">
              {userRegister && userRegister.email}
              {email && email}
              {/* samshrestha@gmail.com */}
            </h3>
            <p className="verify__resend-text">Still didn’t got an email?</p>
            {/* {isFetching ? (
        <div className='verify__resend__Btn'>
          <button className='verify__Btn' style={{ background: '#f1f1f1' }}>
            <GifLoader />
          </button>
        </div>
      ) : ( */}

            <div className="verify__resend__Btn">
              <Button
                style={{ width: "100%", marginTop: "1rem" }}
                variant="contained"
                color="gradient"
                size="md"
                // onClick={() => history.push("/registration-complete")}
                disabled={playing ? "true" : ""}
                isLoading={isFetching}
                onClick={resendEmailhandler}
              >
                <span>Resend Email</span>
              </Button>
              <div className="timer-wrapper" id="timer-wrapper">
                <CountdownCircleTimer
                  // isPlaying={() => setPlaying(true)}
                  isPlaying={playing}
                  duration={60}
                  size={32}
                  strokeWidth={2}
                  colors={[["#0fa6ac", 0.33], ["#4bbd97", 0.33], ["#d21f1f"]]}
                  onComplete={() => {
                    return [true, 1500];
                  }}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>
        </FormContainer>
      </div>
    </Container>
  );
};

export default Verify;
