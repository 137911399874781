import classNames from 'classnames';
import ErrorMessage from 'common/ErrorMessage';
import React from 'react';
import { useController } from 'react-hook-form';
// css
import './index.scss';

const Input = ({
  className,
  style,
  type,
  placeholder,
  value,
  name,
  size,
  control,
  rules,
  errors,
  ...props
}) => {
  const { field } = useController({ control, name, errors });

  return (
    <>
      <input
        style={style}
        className={classNames(className, 'input', `input--${size}`)}
        placeholder={placeholder}
        id={`input#${name}`}
        type={type}
        {...field}
        {...props}
      />

      <ErrorMessage>{errors?.message}</ErrorMessage>
    </>
  );
};

export default Input;
