import { useMediaQuery } from "@material-ui/core";
import Container from "common/Container";
import Line from "common/Line";
import React, { useEffect } from "react";
import FormContainer from "../FormContainer";
import SocialAuth from "../SocialAuth";
import RegisterForm from "./RegisterForm";
import { useHistory } from "react-router-dom";
import { loginSelector } from "../Login/LoginSlice";
import { useSelector } from "react-redux";
const Register = () => {
  const isTabletScreen = useMediaQuery("(max-width: 768px)");
  const history = useHistory();

  const { data, isAuthenticated } = useSelector(loginSelector);

  useEffect(() => {
    if (data.status_code === 202 || isAuthenticated) {
      history.push("/pocket-exam/dashboard");
    }
  }, [data, isAuthenticated, history]);
  return (
    <Container>
      <div style={{ paddingTop: "5rem" }}>
        <FormContainer>
          <SocialAuth />
          <Line orientation={isTabletScreen ? "horizontal" : "vertical"} />
          <RegisterForm />
        </FormContainer>
      </div>
    </Container>
  );
};

export default Register;
