import React from "react";

import "./TokenExpired.css";

const TokenExpired = () => {
  return (
    <div className="box">
      <p>Link has been already used or expire.</p>
    </div>
  );
};

export default TokenExpired;
