import Button from 'common/Button';
import Input from 'common/Input/Input';
import React from 'react';
// css
import './index.scss';

const ChangePasswordForm = ({ changeSuccess }) => {
  return (
    <form className='changepasswordform'>
      <Input type='password' size='md' placeholder='Password' />
      <Input size='md' type='password' placeholder='repeat Password' />
      <Button
        className='changepasswordform__btn'
        color='gradient'
        size='md'
        variant='contained'
        onClick={changeSuccess}
      >
        reset password
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
