import React from 'react';

import { Wrapper } from './SubHeaderElements';
import ClockSvg from 'assets/icons/Clock.svg';

const SubHeader = ({ title, date }) => {
  return (
    <Wrapper>
      <div className='subHeader__container'>
        <p className='subHeader__title'>{title}</p>
        <div className='subHeader__date'>
          <img src={ClockSvg} alt='' />

          <div>
            Last modified: <span>{date}</span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SubHeader;
