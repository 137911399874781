import React from 'react';

import './index.css';

const BodyContainer = ({ children, style }) => {
  return (
    <div style={style} className='body-container'>
      {children}
    </div>
  );
};

export default BodyContainer;
