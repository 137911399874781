import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';
import { GrPrevious, GrNext } from 'react-icons/gr';
import PropTypes from 'prop-types';

const Pagination = ({ handlePageClick, pageCount }) => {
  return (
    <ReactPaginate
      breakLabel='...'
      nextLabel={<GrNext />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel={<GrPrevious />}
      renderOnZeroPageCount={null}
      className='react-pagination'
      nextClassName='pagination-next'
      previousClassName='pagination-previous'
      activeClassName='pagination-active'
      disabledClassName='pagination-disabled'
      pageLinkClassName='pagination-page-link'
      previousLinkClassName='pagination-prev-link'
      nextLinkClassName='pagination-next-link'
    />
  );
};

Pagination.propTypes = {
  handlePageClick: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
};

export default memo(Pagination);
