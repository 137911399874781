import React from "react";
import Line from "common/Line";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "pages/AuthenticationPage/Login/LoginSlice";
import Avatar from "common/Avatar";
// css
import "./index.scss";

const ProfileHover = ({ handleClose, data }) => {
  const dispatch = useDispatch();

  const handlelogout = () => {
    dispatch(logoutUser());
  };
  return (
    <div className="profileHover">
      <div className="profileHover__header">
        <div className="profileHover__header--image">
          <Avatar src={data?.image_uri} size="xl" />
        </div>
        <div className="profileHover__header--userInfo">
          <h4>{data.username}</h4>
          <h5>{data.email}</h5>
          {/* <div className="profileHover__header--userInfo-profile">
            <Link to="/">
              <p onClick={handleClose}>view profile</p>
            </Link>
            <Line orientation="vertical" />
            <Link to="/">
              <p onClick={handleClose}>edit profile</p>
            </Link>
          </div> */}
        </div>
      </div>
      <Line orientation="horizontal" />
      {/* <div className='profileHover__links'>
        <Link to='/'>
          <p onClick={handleClose}>my progress</p>
        </Link>
        <Link to='/'>
          <p onClick={handleClose}>my purchase</p>
        </Link>
        <Link to='/'>
          <p onClick={handleClose}>settings</p>
        </Link>
        <Link to='/'>
          <p onClick={handleClose}>help and support</p>
        </Link>
      </div> */}
      <Line orientation="horizontal" />
      <button className="profileHover__logoutBtn" onClick={handlelogout}>
        log out
      </button>
    </div>
  );
};

export default ProfileHover;
