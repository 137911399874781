import React, { useCallback, useContext, useEffect } from "react";
// css
import "./index.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import discussionForumApi, {
  useGetDiscussionQuery,
} from "app/services/discussionForumApi";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useHistory } from "react-router-dom";
import { ExamDiscussionContext } from "..";
import { useDispatch } from "react-redux";

const ExamDiscussionTable = ({ response }) => {
  const { data: userPreference } = useSelector(userPreferenceSelector);
  const { subjectSortBy } = useContext(ExamDiscussionContext);
  const dispatch = useDispatch();
  const { data, isFetching } = useGetDiscussionQuery(
    {
      slug: userPreference?.course_slug,
      tag:
        typeof subjectSortBy == "undefined" || subjectSortBy === "all"
          ? "All"
          : subjectSortBy,
      page: 1,
      institute: "pocket-exam",
    },
    {}
  );

  useEffect(() => {
    dispatch(
      discussionForumApi.endpoints.getDiscussion.initiate(
        {
          slug: userPreference?.course_slug,
          tag:
            typeof subjectSortBy == "undefined" || subjectSortBy === "all"
              ? "All"
              : subjectSortBy,
          page: 1,
          institute: "pocket-exam",
        },
        { subscribe: false, forceRefetch: true }
      )
    );
  }, [response]);
  const history = useHistory();
  const handleClickReview = useCallback(
    (data, id) =>
      history.push(`/pocket-exam/exam-discussion?id=${id}`, { data }),

    [history]
  );
  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="examDiscussionTable">
        <thead>
          <tr>
            <th>Forum</th>
            <th>replies</th>
            <th>views</th>
            <th>activity</th>
          </tr>
        </thead>
        <tbody>
          {data?.results.map((data) => (
            <tr key={data.id}>
              <td className="examDiscussionTable__td--forum">
                <Link to={`/pocket-exam/exam-discussion?id=${data.id}`}>
                  {data.uploadedImage === null ? null : (
                    <img src={data.uploadedImage} alt="" />
                  )}
                </Link>
                <div
                  className="examDiscussionTable__td--forum-info"
                  onClick={() => handleClickReview(data, data.id)}
                >
                  <h5>{data.message}</h5>
                  <p>{data.tag}</p>
                  {/* </Link> */}
                </div>
              </td>
              <td>{data.comment_count}</td>
              <td>{data.like_count}</td>
              <td className="examDiscussionTable__td--activity">
                <h5>{data.post_at}</h5>
                <p>{data.post_by.username}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExamDiscussionTable;
