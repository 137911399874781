import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/baseQuery';

export const courseListApi = createApi({
  reducerPath: 'courseListApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getCourseList: build.query({
      query: () => ({
        url: `/admin/course-list?institute=pocket-exam`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCourseListQuery } = courseListApi;
