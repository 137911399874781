import { createSlice } from '@reduxjs/toolkit';

const userPreferenceSlice = createSlice({
  name: 'userPreference',
  initialState: {
    data: [],
  },
  reducers: {
    setUserPreference: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: {},
});

export const { setUserPreference } = userPreferenceSlice.actions;

export const userPreferenceSelector = (state) => state.userPreference;

export default userPreferenceSlice.reducer;
