import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
// css
import './index.scss';

const AvatarGroups = ({ leaderboard }) => {
  return (
    <div className='avatarGroups'>
      <AvatarGroup max={3}>
        {leaderboard?.map((user) => (
          <Avatar
            alt={`${user?.user?.username}-avatar`}
            src={user?.user?.image_uri}
            key={user?.user?.username}
          >
            {user?.user?.image_uri === null && user?.user?.username.charAt(0)}
          </Avatar>
        ))}
      </AvatarGroup>
    </div>
  );
};

export default AvatarGroups;
