import MenuDropdown from 'common/MenuDropdown';
import React from 'react';
import { IoMdNotificationsOutline } from 'react-icons/io';
import NotificationHover from './NotificationHover';
// css
import './index.scss';

const NotificationCenter = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className='notificationCenter'>
      <div
        className='notificationCenter__icon'
        onClick={handleOpen}
        ref={anchorRef}
      >
        <IoMdNotificationsOutline />
      </div>

      <MenuDropdown
        notification='true'
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
      >
        <NotificationHover handleClose={handleClose} />
      </MenuDropdown>
    </div>
  );
};

export default NotificationCenter;
