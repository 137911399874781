import React from "react";
import reportIcon from "assets/icons/report__icon.svg";
import replyBlackIcon from "assets/icons/replyBlack__icon.svg";
import favIcon from "assets/icons/fav__icon.svg";
import ReplyBtn from "./ReplyBtn";
import ShareBtn from "./ShareBtn/index";
// css
import "./index.scss";

const ShareReplyReportSection = ({ postid, setcommentSortBy }) => {
  return (
    <section className="sharereplyreport">
      <div className="sharereplyreport__left">
        <ShareBtn postid={postid} />
        <button className="sharereplyreport__left--greyBtn">
          <img src={reportIcon} alt="report-icon" />
          report
        </button>
        <ReplyBtn postid={postid} setcommentSortBy={setcommentSortBy} />
      </div>
      {/* <div className="sharereplyreport__right">
        <button>
          <img src={replyBlackIcon} alt="reply-icon" />
          reply
        </button>
        <button>
          <img src={favIcon} alt="fav-icon" />
          (10)
        </button>
      </div> */}
    </section>
  );
};

export default ShareReplyReportSection;
