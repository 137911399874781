import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useGetUniversityInfoQuery } from "app/services/universityInfoApi";
import Line from "common/Line";
import Select from "common/Select";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// css
import "./index.scss";

const selectData = [
  { id: 0, name: "all course", value: "allcourse" },
  { id: 1, name: "free course", value: "freecourse" },
  { id: 2, name: "paid course", value: "paidcourse" },
];

const HeaderSection = ({
  title,
  isExamDiscussion,
  setSelectType,
  setSelectSortBy,
}) => {
  const { data } = useSelector(userPreferenceSelector);
  const { data: universityData, isLoading } = useGetUniversityInfoQuery(
    {
      slug: data.course_slug,
    },
    { refetchOnMountOrArgChange: 60 * 60 }
  );

  useEffect(() => {
    setSelectType(universityData?.university[0]?.universityTag);

    setSelectSortBy(selectData[0].value);
  }, [setSelectType, universityData?.university, setSelectSortBy]);

  return (
    <>
      <section className="headerSection">
        <h2 className="headerSection__title">{title}</h2>
        {!isExamDiscussion && (
          <div className="headerSection__filterBox">
            {/* <div className="headerSection__dropdownSection">
              <label>SORT BY:</label>
              <Select
                className="headerSection__dropdownSection--select"
                size="sm"
                options={selectData}
                setValue={setSelectSortBy}
              />
            </div> */}
            <div className="headerSection__dropdownSection type">
              <label>Type:</label>
              <Select
                className="headerSection__dropdownSection--select"
                size="sm"
                options={universityData?.university}
                isLoading={isLoading}
                setValue={setSelectType}
              />
            </div>
          </div>
        )}
      </section>
      <Line orientation="horizontal" />
    </>
  );
};

export default HeaderSection;
