import React from "react";

import Notification from "./Notification/Notification";
// import DownloadApp from './DownloadApp/DownloadApp';
import OurServices from "./OurServices/OurServices";
import MyPerformance from "./MyPerformance/MyPerformance";
import Members from "./Members/Members";
import FollowAcademy from "./FollowAcademy/FollowAcademy";
// css
import "./index.scss";
import { userPreferenceSelector } from "app/features/userPreferenceSlice";
import { useGetPerformanceQuery } from "app/services/myPerformanceApi";
import { useSelector } from "react-redux";
function RightContent() {
  const { data: userPreference } = useSelector(userPreferenceSelector);

  const { data, isFetching } = useGetPerformanceQuery(
    {
      slug: userPreference?.course_slug,
      time: "All",
      page: 1,
      institute: "pocket-exam",
      testname: "",
    },
    { refetchOnMountOrArgChange: 60 }
  );
  return (
    <div className="rightContent">
      <Notification />
      {/* <DownloadApp /> */}
      {/* <OurServices /> */}
      <MyPerformance data={data} />
      <Members data={data} />
      <FollowAcademy />
    </div>
  );
}

export default RightContent;
